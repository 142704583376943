import React from "react";
import { useTranslation } from "react-i18next";

export interface I18nProviderProps {
  children: React.ReactNode;
}

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const { t } = useTranslation();

  return (
    <div lang={t("lang")} dir={t("dir")} className={t("dir")}>
      {children}
    </div>
  );
};
