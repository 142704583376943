import React from "react";
import { CustomRenderLeafProps } from "@sumit-platforms/types";
import classNames from "classnames";

const Leaf = ({ attributes, children, leaf }: CustomRenderLeafProps) => {
  return (
    <span
      {...attributes}
      className={classNames({
        highlightGreen: leaf.highlightGreen,
      })}
    >
      {children}
    </span>
  );
};

export default Leaf;
