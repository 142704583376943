import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
} from "react-router-dom";

import useAuth from "./hooks/useAuth";
import {
  useFeatureFlag,
  useKeyboardShortcuts,
} from "@sumit-platforms/ui-bazar/hooks";
import { useUser } from "./store";

import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import { BreathingLoader, ErrorPage } from "@sumit-platforms/ui-bazar";

//PAGES
import Dashboard from "./pages/Dashboard";
import Guest from "./pages/Guest";
import Job from "./pages/Job/Job";
import UploadFiles from "./pages/UploadFiles/UploadFiles";
import Deliveries from "./pages/Deliveries/Deliveries";
import Extract from "./pages/Extract/Extract";

import LoginPage from "./pages/Auth/LoginPage";
import SignUpPage from "./pages/Auth/SignUpPage";
import ResetPasswordPage from "./pages/Auth/ResetPasswordPage";
import NotFound from "./pages/NotFound/NotFound";
import Extract2 from "./pages/Extract/Extract2";
import { useTranslation } from "react-i18next";
import { faBan } from "@fortawesome/pro-light-svg-icons";

const B2B = () => {
  const { t } = useTranslation();
  const { userLoading } = useAuth();
  const { user } = useUser();

  const newExtract = useFeatureFlag("newExtract");
  const { initShortcuts } = useKeyboardShortcuts();

  useEffect(() => {
    initShortcuts(user?.settings);
  }, [initShortcuts, user?.settings]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
      children: [
        {
          path: "/",
          element: <Deliveries />,
        },
        {
          path: "/upload-files",
          element: <UploadFiles />,
        },
        {
          path: "/extract",
          element: <Extract />,
        },
        {
          path: "/extract2",
          element: newExtract ? <Extract2 /> : <Extract />,
        },
        {
          path: "/job/:idJob",
          element: <Job />,
        },
        {
          path: "unauthorized",
          element: (
            <ErrorPage
              title={t("unauthorized")}
              text={t("unauthorized_text")}
              icon={faBan}
              redirectToOnClick={"/"}
            />
          ),
        },
        {
          path: "/*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: "/",
      element: <Guest />,
      children: [
        {
          path: "signup",
          element: <SignUpPage />,
        },
        {
          path: "login",
          element: <LoginPage />,
        },
        {
          path: "password-reset",
          element: <ResetPasswordPage />,
        },
        {
          path: "*",
          element: <LoginPage />,
        },
      ],
    },
  ]);

  return userLoading ? (
    <BreathingLoader
      className={userLoading ? "fadeIn" : "fadeOut"}
      image={sumitLogoBlack}
    />
  ) : (
    <RouterProvider router={router} />
  );
};

export default B2B;
