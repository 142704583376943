import React, { useMemo } from "react";

import { Box } from "@mui/material";
import { Button } from "./../Button/Button";
import { DateRange } from "../DateRange/DateRange";
import { format } from "date-fns";
import { DATE_FORMAT_1 } from "../../constants/globals";
import { PopoverAction } from "../../popovers/PopoverAction/PopoverAction";
import { blackColor } from "../../bazar-theme";

export interface DateRangeInputProps {
  valueFrom: Date;
  valueTo: Date;
  onValueFromChange: (date: Date) => void;
  onValueToChange: (date: Date) => void;
  disabled?: boolean;
}

export const DateRangeInput = ({
  valueFrom,
  valueTo,
  onValueFromChange,
  onValueToChange,
  disabled,
}: DateRangeInputProps) => {
  const displayed = useMemo(() => {
    return `${format(valueFrom, DATE_FORMAT_1)} - ${format(
      valueTo,
      DATE_FORMAT_1
    )}`;
  }, [valueFrom, valueTo]);

  return (
    <Box className={"DateRangeInput"}>
      <PopoverAction
        disabled={disabled}
        trigger={
          <Button
            disabled={disabled}
            variant={"outlined"}
            sx={{
              padding: "5px 16px",
              width: "100%",
              height: "35px",
              justifyContent: "start",
              color: blackColor,
            }}
          >
            {displayed}
          </Button>
        }
        renderContent={() => (
          <Box onClick={(e) => e.stopPropagation()}>
            <DateRange
              valueFrom={valueFrom}
              valueTo={valueTo}
              onValueFromChange={onValueFromChange}
              onValueToChange={onValueToChange}
            />
          </Box>
        )}
      />
    </Box>
  );
};
