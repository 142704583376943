import { CustomEditor } from "@sumit-platforms/types";
import EditorService from "../services/EditorService";
import _ from "lodash";

export const withRangeBreak = (editor: CustomEditor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    if (operation.type === "split_node") {
      apply(operation);

      if (_.isEmpty(operation.properties)) return;

      EditorService.onBreakRange(operation, editor);

      return;
    }

    // For all other operations, apply them normally
    apply(operation);
  };

  return editor;
};
