import {
  faFilter,
  faAngleRight,
  faAngleLeft,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, InputBase, Paper } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Sort, SortOption } from "../Sort/Sort";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { Tooltip } from "../Tooltip/Tooltip";

import "./SearchInput.scss";

export interface SearchInputProps {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  showTextInput: boolean;
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  hasFilter?: boolean;
  toggleFilter?: (isOpen: boolean) => void;
  filterBtnText?: string;
  isFilterOpen?: boolean;
  showSearchIcon?: boolean;
  direction?: "ltr" | "rtl" | string;
  className?: string;
  autoFocus?: boolean;
  forwardRef?: React.MutableRefObject<any>;
  sortOptions?: SortOption[];
  onSortChange?: (order: "ASC" | "DESC", orderBy: string) => void;
  icon?: IconProp;
  iconSize?: SizeProp;
  dirty?: boolean;
  dirtyTooltipText?: string;
}

export const SearchInput = forwardRef(
  (
    {
      onChange,
      value,
      placeholder,
      style,
      showTextInput,
      children,
      hasFilter = false,
      toggleFilter,
      filterBtnText,
      isFilterOpen,
      showSearchIcon = true,
      direction,
      className,
      autoFocus = false,
      forwardRef,
      sortOptions,
      onSortChange,
      icon,
      iconSize,
      dirty,
      dirtyTooltipText,
    }: SearchInputProps,
    ref
  ) => {
    const inputRef = useRef(null);
    useImperativeHandle(
      ref,
      () => {
        return {
          inputRef: (inputRef?.current as unknown as HTMLDivElement)
            ?.firstChild as HTMLInputElement,
        };
      },
      [inputRef.current]
    );

    return (
      <Paper
        className={classNames(`BazarSearchInput ${className}`, {
          dirty: dirty,
        })}
        component="form"
        sx={{
          borderRadius: "10px",
          width: "100%",
          padding: hasFilter ? "6px 0px" : "0",
          paddingInlineEnd: hasFilter ? "20px" : "0",
          boxShadow: "none",
          minHeight: hasFilter ? "auto" : style?.height || "52px",
        }}
        style={{
          minHeight: style?.height,
          backgroundColor: style?.backgroundColor,
          fontSize: style?.fontSize,
          display: hasFilter ? "" : "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: style?.justifyContent,
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: style?.justifyContent,
              width: "100%",
              alignContent: "center",
            }}
          >
            {showSearchIcon && (
              <IconButton
                type="submit"
                aria-label="search"
                className={"inputPrefixIcon"}
                disabled={true}
              >
                <FontAwesomeIcon
                  icon={icon ? icon : faSearch}
                  color={style?.color}
                  size={iconSize}
                />
              </IconButton>
            )}
            {showTextInput && (
              <InputBase
                autoFocus={autoFocus}
                sx={{
                  width: "100%",
                  color: style?.color,
                  "& ::-webkit-input-placeholder": {
                    color: style?.color,
                    opacity: "1 !important",
                  },
                }}
                style={{
                  padding: showSearchIcon ? "0" : "0 15px",
                }}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                ref={inputRef}
                endAdornment={
                  dirty ? (
                    <Tooltip title={dirtyTooltipText || ""}>
                      <span className={"dirtySpanIndicator"}>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </span>
                    </Tooltip>
                  ) : null
                }
              />
            )}
          </div>
          {sortOptions && (
            <Sort
              options={sortOptions}
              onSortChange={onSortChange}
              direction={direction as string}
            ></Sort>
          )}
          {hasFilter && (
            <Button
              disableElevation
              disableRipple
              className={"filterBtn"}
              onClick={() => toggleFilter && toggleFilter(!isFilterOpen)}
              endIcon={
                <FontAwesomeIcon
                  icon={direction === "ltr" ? faAngleRight : faAngleLeft}
                />
              }
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1}
              >
                <Box>
                  <FontAwesomeIcon icon={faFilter} />
                </Box>
                <Box>{filterBtnText}</Box>
              </Box>
            </Button>
          )}
        </div>
        {hasFilter && isFilterOpen && children}
      </Paper>
    );
  }
);
