import _ from "lodash";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Box, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { useEllipsisObserver } from "../../../hooks";

import "./TableCell.scss";

export interface TableCellToolTip {
  icon: JSX.Element;
  content: JSX.Element;
  color: string;
}
interface TableCellProps {
  primaryText?: string | null;
  primarySmallText?: string | null;
  primaryLink?: string | null;
  secondaryText?: string | null;
  secondarySmallText?: string | null;
  icon?: string;
  boldTitle?: boolean;
  toolTip?: TableCellToolTip;
  linkFlavor?: "light" | "hidden";
}

export const TableCell: FC<TableCellProps> = ({
  primaryText = "",
  primarySmallText,
  primaryLink,
  secondaryText = "",
  secondarySmallText,
  icon,
  boldTitle = false,
  toolTip,
  linkFlavor,
}) => {
  const primaryTextRef = useRef<HTMLDivElement>(null);
  const { isEllipsised } = useEllipsisObserver(primaryTextRef);

  return (
    <Box className={"TableCell ellipsis"}>
      {icon && (
        <Box className={"briefImageWrapper"}>
          <img alt="icon" src={icon}></img>
        </Box>
      )}
      <Box className={"briefTextWrapper"}>
        <Box display={"flex"} gap={1} alignItems={"flex-start"}>
          <Tooltip
            title={
              isEllipsised ? (
                <span>
                  {primaryText || ""} {primarySmallText || ""} <br />
                  {secondaryText || ""} {secondarySmallText || ""}
                </span>
              ) : null
            }
          >
            <Box ref={primaryTextRef} className={"ellipsis"}>
              {primaryLink ? (
                <Link
                  className={classNames(
                    "primaryText primaryLinkText",
                    `link${_.capitalize(linkFlavor)}`
                  )}
                  to={primaryLink}
                >
                  {primaryText}
                </Link>
              ) : (
                <Typography
                  fontWeight={boldTitle ? 600 : 400}
                  className="primaryText"
                >
                  {primaryText}
                  {primarySmallText && (
                    <span className="primarySmallText">{primarySmallText}</span>
                  )}
                </Typography>
              )}
              {(secondaryText || primarySmallText) && (
                <>
                  <br />
                  <Typography className="secondaryText">
                    {secondaryText}
                    {primarySmallText && (
                      <span className="secondarySmallText">
                        {secondarySmallText}
                      </span>
                    )}
                  </Typography>
                </>
              )}
            </Box>
          </Tooltip>
          {toolTip && (
            <Box
              display={"display"}
              justifyContent={"center"}
              alignContent={"center"}
              mt={0.3}
            >
              <Tooltip placement="bottom-start" title={toolTip.content}>
                <span style={{ color: toolTip.color }}>{toolTip.icon}</span>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
