import {
  SpeakersDocxConfig,
  TableDocxConfig,
} from "../config-settings/DocxConfig";
import { EmptySubtitle, ZeroSubtitle } from "../entities/Job";

type ExportOptions = {
  format: string;
  showSpeakers?: boolean;
  interval?: number;
  flip?: boolean;
  tc?: string | null;
  encoding?: ExportEncoding;
  idDocxTemplate?: number;
  docxDefaultSettings?: SpeakersDocxConfig | TableDocxConfig;
  zeroSubtitle?: ZeroSubtitle;
  emptySubtitle?: EmptySubtitle;
  autoBreak?: boolean;
};

type ExportEncoding = "ISO-8859-8" | "UTF-8";

type ExportedFile = { filename: string; data: string; url?: string };

const SUBTITLES_EDIT_FORMAT_MAP: Record<string, string> = {
  // assa: "ass",
  // ass: "ass",
  // ssa: "ssa",
  // netflix: "dfxp",
  // itt: "itt",
  // ttml: "xml",
  // ebu: "stl",
  // ebustl: "stl",
  // fpc: "fpc",
  // unipac: "fpc",
  // pacunicode: "fpc",
  pac: "pac",
  cavena890: "890",
};

const SubtitlesFormats = ["srt", "ass", "ssa", "txt"];

const ExportEncodingOptions = ["ISO-8859-8", "UTF-8"];

export type { ExportOptions, ExportEncoding, ExportedFile };
export { ExportEncodingOptions, SUBTITLES_EDIT_FORMAT_MAP, SubtitlesFormats };
