import config from "../../config";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { faArrowDownToLine, faTag } from "@fortawesome/pro-light-svg-icons";

import {
  ConfirmModal,
  GeneralTable,
  TagsModal,
  ExportJobModal,
  SearchAndFilters,
  CreateTranslationModal,
  ContextOption,
  SummarizeJobModal,
} from "@sumit-platforms/ui-bazar";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import {
  jobDefaultQuery,
  availableStatusesMap,
  JOBS_INITIAL_QUERY_LIMIT,
  JOBS_INITIAL_QUERY_OFFSET,
  JOBS_INITIAL_ORDER,
  JOBS_INITIAL_ORDER_BY,
} from "@sumit-platforms/ui-bazar/constants";
import {
  getTcOffsetByStartTime,
  isJobReadyForSummarizeClients,
  isJobReadyForTranslation,
  manageMustJobStatusesForQuery,
} from "@sumit-platforms/ui-bazar/utils";
import jobService from "../../services/jobService";
import * as searchService from "../../services/searchService";

import {
  Client,
  ExportOptions,
  IndexSearch,
  Job,
  JobFilterOptions,
  JobStatus,
  JobTypes,
  QueryParams,
  Tag,
  TranslationData,
  languages,
} from "@sumit-platforms/types";

import useJobs from "../../hooks/useJobs";
import useSpeakers from "../../hooks/useSpeakers";
import { useUser } from "../../store/user";
import { useGlobalData } from "../../store/globalData";
import { clientStore } from "../../store/client";

import tagService from "../../services/tagService";
import { EditJobModal } from "./modals/EditJobModal/EditJobModal";
import { exportService as ExportService } from "@sumit-platforms/ui-bazar/services";
import {
  useFilters,
  useHeadCells,
  useQuery,
  useSortOptions,
  useTags,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";

import "./Deliveries.scss";

const exportService = ExportService({ config });

export interface JobRow {
  id: number;
  name: string;
  projectName: string;
  type: keyof JobTypes;
  duration: number;
  createdAt: Date;
  contextMenu: any;
  searchContext: string;
  language: { input: string[]; output: string[] };
  tags: Tag[];
  client: Client;
  status: JobStatus;
  job: Job;
  indexSearch?: IndexSearch[];
}

const Deliveries = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { client } = clientStore();
  const { sortOptions } = useSortOptions([
    "created_at",
    "name",
    "duration",
    "status",
  ]);
  const jobIdsByContextSearch = useRef<number[]>([]);
  const availableStatuses = useMemo(
    () => availableStatusesMap.must.deliveries,
    []
  );

  const { speakers } = useSpeakers({
    user,
  });
  const { jobTypes, setToast } = useGlobalData();

  const { tags } = useTags({ entity: "job", tagService });
  const { toastError, toastSuccess } = useToast({ setToast });

  const isTranscriptEditorAllowed = useMemo(() => {
    const transcriptEditorFF = _.get(config, "featureFlags.transcriptEditor");
    if (
      transcriptEditorFF &&
      transcriptEditorFF.enabled
      // transcriptEditorFF.users.includes(user?.idUser || 0)
    ) {
      return true;
    }
    return false;
  }, [user?.idUser]);

  const {
    onScroll,
    onSearch,
    onFilterChange,
    onSort,
    loading: isQueryLoading,
    querySearch,
  } = useQuery<JobFilterOptions>(
    {
      queryLimit: JOBS_INITIAL_QUERY_LIMIT,
      queryOffset: JOBS_INITIAL_QUERY_OFFSET,
      order: JOBS_INITIAL_ORDER,
      orderBy: JOBS_INITIAL_ORDER_BY,
      onBuildQuery,
      onResetOffset: () => {
        setJobs([]);
      },
    },
    [client]
  );

  async function onBuildQuery({
    query,
  }: {
    query: QueryParams<JobFilterOptions>;
  }) {
    if (_.isEmpty(client) || _.isUndefined(client)) return;
    if (!client) return;
    jobIdsByContextSearch.current = [];
    const _queryFilters = { ...query.filters };
    if (query.search || query.filters) {
      if (query.search) {
        jobIdsByContextSearch.current = await searchService.searchJobContext(
          query.search
        );
        _queryFilters.idJob = jobIdsByContextSearch.current;
      }
      _queryFilters.status = manageMustJobStatusesForQuery(
        _queryFilters?.status as JobStatus[]
      );
      _queryFilters.status = _queryFilters?.status || availableStatuses;
      _queryFilters.clientIds = [client.idClient];
      query.filters = _queryFilters;
    }
    await getJobs(query);
  }

  const {
    jobs,
    getJobs,
    setJobs,
    jobQueryMetaData,
    getJobQueryMetaData,
    totalJobs,
    hasMore,
  } = useJobs();

  const defaultQuery: JobFilterOptions = useMemo(() => {
    const _defaultQuery = { ...jobDefaultQuery };
    _defaultQuery.durationEnd = jobQueryMetaData.maxDuration;
    return _defaultQuery;
  }, [jobDefaultQuery, jobQueryMetaData]);

  useEffect(() => {
    if (_.isEmpty(client) || _.isUndefined(client)) return;
    getJobQueryMetaData({
      filters: {
        clientIds: [client.idClient],
        status: availableStatuses,
      },
    });
  }, [client]);

  const { setModalContent, clearModalContent, setModalType } = useModal();

  useEffect(() => {
    if (_.isArray(jobs)) {
      const newJobRows = jobs.map(createJobRow);
      setJobRows(
        newJobRows.map((j: JobRow) =>
          jobIdsByContextSearch.current.includes(j.id)
            ? { ...j, searchContext: querySearch.current }
            : j
        )
      );
    }
  }, [jobs]);

  const [selected, setStelected] = useState<number[]>([]);
  const selectedJobs: Job[] | null = useMemo(
    () =>
      selected.length
        ? jobs.filter(({ idJob }) => selected.includes(idJob))
        : null,
    [jobs, selected]
  );

  const handleJobDelete = (row: JobRow) => {
    openConfirmModal(
      t("delete_job"),
      t("are_you_sure"),
      async () => {
        await deleteJob([row.id]);
        setJobs(() => {
          return jobs.filter((job) => row.id !== job.idJob);
        });
      },
      true
    );
  };

  function getTableContext(row: JobRow): ContextOption[] {
    return [
      {
        name: t("export_job"),
        disabled: ![JobStatus.done, JobStatus.archive].includes(row.status),
        action: () =>
          openExportJobModal({
            jobIds: [row.id],
            jobName: row.name,
            jobType: row.type,
            templates: row?.client.templates,
            tcOffset: getTcOffsetByStartTime(row.job.tcOffsets),
            selectedJobs: [row.job] as Job[],
          }),
      },
      {
        name: "export_brief",
        action: () => openSummarizeJobModal(row.id, row.name),
        disabled: !isJobReadyForSummarizeClients(row.job),
      },
      {
        name: t("edit"),
        action: () => openEditJobModal(row),
      },
      {
        name: t("tags"),
        action: () => openTagsModal([row], row.tags),
      },
      {
        name: t("translation_order"),
        action: () => openCreateTranslationModal(row.job),
        disabled: !isJobReadyForTranslation(row.job),
      },
      {
        name: t("delete_job"),
        color: "red",
        action: () => handleJobDelete(row),
      },
      {
        name: t("cancel"),
        color: "grey",
        action: () => console.log(row),
      },
    ];
  }

  const handleExportMany = () => {
    const selectedJobTypes = selectedRows.map((s) => s.type);
    const commonJobType = selectedJobTypes.every(
      (t) => t === selectedJobTypes[0]
    )
      ? selectedJobTypes[0]
      : undefined;

    let templates: any[] = [];
    if (selectedRows[0].client?.templates?.length > 0) {
      templates = selectedRows[0].client?.templates;
    }

    openExportJobModal({
      jobIds: selected,
      jobType: commonJobType,
      templates,
      selectedJobs: selectedJobs as Job[],
    });
  };

  const createJobRow = (job: Job): JobRow => {
    return {
      id: job.idJob,
      name: job.name,
      projectName: job.projectName,
      type: (job.type.typeName as keyof JobTypes) || "",
      duration: job.duration || 0,
      createdAt: new Date(job.createdAt),
      contextMenu: null,
      searchContext: "",
      language: { input: job.inputLanguage, output: job.outputLanguage },
      tags: job.tags || [],
      client: job.client,
      status: job.status,
      job: job,
      indexSearch: job.indexSearch,
    };
  };

  const [jobRows, setJobRows] = useState<JobRow[]>([]);
  const selectedRows = useMemo(() => {
    return jobRows.filter((j: JobRow) => selected.includes(j.id));
  }, [selected, jobRows]);

  const { headCells } = useHeadCells({
    selectedRows,
    headCellsKeys: [
      "id",
      "mustJobName",
      "project",
      "mustType",
      "duration",
      "mustJobStatus",
      "contextMenu",
    ],
    cellLink: {
      name: (r) => `/job/${r.id}`,
    },
    tableContextCallBack: getTableContext,
    styles: {
      mustJobName: {
        width: "35%",
      },
      mustType: {
        width: "15%",
      },
      contextMenu: {
        width: "1%",
      },
    },
    barColActions: {
      name: [
        {
          name: "export",
          icon: faArrowDownToLine,
          action: handleExportMany,
          disabled:
            selected.length < 2 ||
            !selectedRows.every((s) =>
              [JobStatus.done, JobStatus.archive].includes(s.status)
            ),
        },
        {
          name: "tag",
          icon: faTag,
          action: () => {
            openTagsModal(selectedRows, []);
          },
          disabled:
            selected.length < 2 ||
            _.unionBy(selectedRows, "job.client.idClient").length > 1,
        },
      ],
    },
  });

  const { filtersScheme } = useFilters({
    customJobStatuses: availableStatuses,
    metaData: {
      maxDuration: jobQueryMetaData.maxDuration,
      projectNames: jobQueryMetaData.projectNames,
      jobTypes,
      tags,
      speakers,
      users: jobQueryMetaData.users,
    },
    filterKeys: [
      "mustJobStatus",
      "projectName",
      "uploaded",
      "users",
      "type",
      "languages",
      "length",
      "tags",
      "speakers",
    ],
  });

  const handleCreateJobTranslationFromJob = async ({
    idJobs,
    translationData,
  }: {
    idJobs: number[];
    translationData: TranslationData[];
  }) => {
    try {
      await jobService.createJobTranslationFromJob({
        idJobs,
        translationData,
      });
      toastSuccess(t("translation_success"));
    } catch (e) {
      toastError(t("translation_failed"));
      console.log("error :", e);
    } finally {
      closeModal();
    }
  };

  const openCreateTranslationModal = (job: Job) => {
    setModalContent(
      <CreateTranslationModal
        jobs={[job]}
        languages={languages.map((l) => l.value)}
        onApprove={handleCreateJobTranslationFromJob}
        onCancel={closeModal}
        restrictOneJobPerLanguage={true}
      />
    );
  };
  const openEditJobModal = (row: JobRow) => {
    setModalContent(
      <EditJobModal
        save={(updatedJob) => handleSaveJob(updatedJob, row)}
        cancel={closeModal}
        job={row.job}
      />
    );
  };

  const handleSaveJob = async (updatedJob: Job, row: JobRow) => {
    await updateJob([row.id], updatedJob);
    setJobRows((jobRows) => {
      return jobRows.map((jobRow) => {
        if (jobRow.id === row.id) {
          return {
            ...row,
            name: updatedJob.name,
            job: {
              ...jobRow.job,
              name: updatedJob.name,
              tcOffset: getTcOffsetByStartTime(updatedJob.tcOffsets),
              tcOffsets: updatedJob.tcOffsets,
            },
          };
        }
        return jobRow;
      });
    });
  };

  const handleSummarizeJob = async (idJob: number, fileName: string) => {
    try {
      await exportService.createJobSummary({ idJob, fileName });
    } catch (err) {
      toastError(t("export_brief_failed"));
    } finally {
      closeModal();
    }
  };

  const openTagsModal = (rows: JobRow[], tags: Tag[]): void => {
    const _jobRow: JobRow[] = _.unionBy(rows, "job.client.idClient");
    if (_jobRow?.length === 1)
      setModalContent(
        <TagsModal
          entity="job"
          submit={(_tags) =>
            setTags(
              rows.map((r) => r.id),
              _tags
            )
          }
          cancel={closeModal}
          tags={tags}
          idClient={_jobRow[0].job.client.idClient}
          tagService={tagService}
        />
      );
  };

  const openConfirmModal = (
    title: string,
    message: string,
    confirm: () => void,
    closeAfterConfirm = true
  ): void => {
    setModalType("danger");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  };
  const openExportJobModal = ({
    jobIds,
    jobName,
    jobType,
    templates,
    tcOffset = 0,
    selectedJobs,
  }: {
    jobIds: number[];
    selectedJobs: Job[];
    jobName?: string;
    jobType?: string;
    templates?: any[];
    tcOffset?: any;
  }): void => {
    const isMultipleExport = jobIds.length > 1;
    setModalContent(
      <ExportJobModal
        jobTypes={jobTypes}
        jobType={jobType}
        jobName={jobName}
        templates={templates}
        tcOffset={isMultipleExport ? null : tcOffset}
        bulkExport={isMultipleExport}
        confirm={async (fileName, options) =>
          await handleExportJob({
            jobIds,
            fileName,
            format: options.format,
            showSpeakers: options.showSpeakers,
            interval: options.interval,
            flip: options.flip,
            tc: options.tc,
            idDocxTemplate: options.idDocxTemplate,
            encoding: options.encoding,
            docxDefaultSettings: options.docxDefaultSettings,
            zeroSubtitle: options.zeroSubtitle,
            emptySubtitle: options.emptySubtitle,
            autoBreak: options.autoBreak,
          })
        }
        cancel={closeModal}
        disabled={false}
        selectedJobs={selectedJobs}
      />
    );
  };

  const openSummarizeJobModal = (idJob: number, jobName: string) => {
    setModalContent(
      <SummarizeJobModal
        idJob={idJob}
        jobName={jobName}
        onApprove={handleSummarizeJob}
        onCancel={closeModal}
      />
    );
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  //API
  const updateJob = async (idJob: number[], job: Partial<Job>) => {
    try {
      await jobService.update(idJob, job);
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const setTags = async (idJob: number[], tags: Tag[]): Promise<void> => {
    try {
      await jobService.setTags(
        idJob,
        tags.map((t) => t.idTag)
      );
      setJobRows((prev) =>
        prev.map((j) =>
          idJob.includes(j.id) ? { ...j, tags: [...tags, ...j.tags] } : j
        )
      );
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteJob = async (idJob: number[]) => {
    try {
      await jobService.deleteJob(idJob);
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const handleExportJob = async (
    exportArgs: ExportOptions & {
      jobIds: number[];
      fileName: string;
    }
  ) => {
    try {
      await exportService.exportJob(exportArgs);
    } catch (err) {
      toastError(t("export_failed"));
    } finally {
      closeModal();
    }
  };

  return (
    <Grid
      container
      className="Deliveries Page"
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} mb={3}>
        <h1 className="pageTitle">{t("deliveries")}</h1>
        <h3 className="pageSubTitle">
          {`${t("showing")} ${jobRows?.length} ${t("from")} ${totalJobs}`}
          {selected.length > 1
            ? `  (${selected.length} ${t("were_selected")}) `
            : ``}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          direction={t("dir")}
          onSearch={onSearch}
          filters={filtersScheme}
          searchPlaceholder={t("search")}
          filterBtnTitle={t("filters")}
          title={t("filter_by")}
          defaultQuery={defaultQuery}
          onFilterChange={onFilterChange}
          isLoading={isQueryLoading}
          onSortChange={onSort}
          sortOptions={sortOptions}
          deps={[client]}
        />
      </Grid>
      <Grid item xs={11} mb={3}>
        <GeneralTable
          headCells={headCells}
          rows={jobRows}
          selected={selected}
          setSelected={setStelected}
          onLoadMore={onScroll}
          loading={isQueryLoading}
          hasMore={hasMore}
        />
      </Grid>
    </Grid>
  );
};

export default Deliveries;
