import _ from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import {
  Client,
  Job,
  JobFilterOptions,
  QueryParams,
} from "@sumit-platforms/types";
import jobService from "./../services/jobService";

const useJobs = (): {
  jobs: Job[];
  getJobs: (query?: QueryParams<JobFilterOptions>) => Promise<void>;
  setJobs: Dispatch<SetStateAction<Job[]>>;
  jobQueryMetaData: any;
  getJobQueryMetaData: (query?: QueryParams<JobFilterOptions>) => Promise<void>;
  totalJobs: number;
  hasMore: boolean;
} => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalJobs, setTotalJobs] = useState<number>(0);

  const [jobQueryMetaData, setJobQueryMetaData] = useState<{
    projectNames: string[];
    maxDuration: number;
    clients: Client[];
    totalJobs: number;
  }>({ projectNames: [], maxDuration: 0, clients: [], totalJobs: 0 });

  const getJobQueryMetaData = async (query?: QueryParams<JobFilterOptions>) => {
    const _jobQueryMetaData = await jobService.getJobQueryMetaData(query);
    setJobQueryMetaData(_jobQueryMetaData);
  };

  const getJobs = async (query?: QueryParams<JobFilterOptions>) => {
    const { jobs, totalJobs } = await jobService.getUserClientJobs(query);
    setTotalJobs(totalJobs);
    setJobs((prev) => {
      if (query && _.isNumber(query?.offset) && query.offset > 0) {
        const aggregatedJobs = [...prev, ...jobs];
        setHasMore(aggregatedJobs.length !== totalJobs);
        return aggregatedJobs;
      } else {
        setHasMore(jobs.length !== totalJobs);
        return jobs;
      }
    });
  };

  return {
    jobs,
    getJobs,
    setJobs,
    jobQueryMetaData,
    getJobQueryMetaData,
    totalJobs,
    hasMore,
  };
};

export default useJobs;
