import _ from "lodash";
import { JobRange } from "@sumit-platforms/types";
import TimeService from "../services/TimeService";

export const createVtt = (
  ranges: JobRange[],
  encodeBase64 = true,
  plainWords?: string,
  index?: number
) => {
  let vttFormat = `WEBVTT\n\n`;

  ranges.map((range, i) => {
    let endTime = range.et;
    if (!range.et) {
      // This error occures because of empty ranges in the new editor.
      // The fix is already in the new editor, but it's not in the old one.
      console.error(
        `et is missing in range index: ${i}, defaulting to st: ${range.st}.`
      );
      endTime = range.st;
    }
    vttFormat += `${TimeService.getTimeStringFromSecs(
      range.st,
      false,
      true
    )} --> ${TimeService.getTimeStringFromSecs(endTime, false, true)}\n`;
    const rangeString =
      plainWords && index === i
        ? plainWords
        : range.words
            .map((word, i) => {
              let wordText = word.word;
              if (_.has(word, "line_ix") && range.words[i + 1]) {
                const lineBreak = word.line_ix !== range.words[i + 1].line_ix;
                wordText = lineBreak ? `${wordText}\n` : wordText;
              }
              return wordText;
            })
            .join(" ")
            .replace(/\n /g, "\n");
    vttFormat += `${rangeString}\n\n`;
  });
  if (encodeBase64) {
    const b64 = window.btoa(unescape(encodeURIComponent(vttFormat)));
    return b64;
  } else {
    return vttFormat;
  }
};
