import React, { FC, useMemo, useCallback } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import { AutoSizer, List } from "react-virtualized";
import EditorService from "./services/EditorService";
// import Logger from "../../services/Logger";
// import FeatureFlagsService from "../../services/FeatureFlagsService";

import {
  Word,
  RangeValidationConfig,
  SubtitlesRange,
  JobWithData,
  SaveTriggers,
} from "@sumit-platforms/types";

import RangeSubtitles from "./components/Ranges/RangeSubtitles";
// import MessageModal from "../../components/MessageModal/MessageModal";

import {
  scrollInto,
  focusAndSetCursor,
  scrollToRange,
} from "./utils/focusAndScroll";
import { generateId } from "./utils/generateId";
import { EditorAction } from "./types/EditorAction";
import { useFeatureFlag } from "@sumit-platforms/ui-bazar/hooks";
import { useRecoilValue } from "recoil";
import { featureFlagsState } from "./store/states";

// const logger = Logger("EditorPage");

interface Props {
  job: JobWithData;
  saveJob: (saveMethod: SaveTriggers) => Promise<void>;
  ranges: SubtitlesRange[];
  updateRanges: (ranges: SubtitlesRange[], rangeIndex?: number) => void;
  focusedRangeIndex?: number;
  setFocusedRangeIndex?: (rangeIx: number) => void;
  addActions: (actions: EditorAction[]) => void;
  currentPlayingRangeIndex: number;
  timestampsEnterMethod: "word" | "player";
  toggleTimestampsEnterMethod: () => void;
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text";
  }) => void;
  rangeValidationConfig?: RangeValidationConfig;
  editorDirection: "rtl" | "ltr";
  isDisabled: boolean;
  toast: (msg: string, type: "success" | "error" | "info") => void;
}

const Subtitles: FC<Props> = ({
  job,
  ranges,
  updateRanges,
  focusedRangeIndex,
  setFocusedRangeIndex,
  addActions,
  currentPlayingRangeIndex,
  saveJob,
  updateRangeTimes,
  rangeValidationConfig,
  editorDirection,
  timestampsEnterMethod,
  toggleTimestampsEnterMethod,
  isDisabled,
  toast,
}): JSX.Element => {
  const featureFlags = useRecoilValue(featureFlagsState);

  // const navigate = useNavigate();

  // const [showAlignModal, setShowAlignModal] = useState(false);

  // const subtitlesActions = [
  //   // {
  //   //   key: "createSubtitles",
  //   //   label: t("align_subtitles"),
  //   //   icon: ["fal", "stream"],
  //   //   onClick: () => setShowAlignModal(true),
  //   // },
  //   {
  //     key: "timestampsEnterMethod",
  //     label: t("timestamps_enter_method_1", { timestampsEnterMethod }),
  //     icon: ["fal", "level-up"],
  //     className: "timestampsEnterMethod",
  //     onClick: () => toggleTimestampsEnterMethod(),
  //     featureFlag: "enterMode",
  //   },
  // ];

  // useEffect(() => {
  //   addActions(subtitlesActions);
  //   if (FeatureFlagsService.isEnabled("saveUserLastPosition", loggedInUser)) {
  //     const { cursorPosition, rangeIx, scrollOffsetTop } =
  //       EditorService.getLastPosition(job.roomId);
  //     scrollToOffset("editorContainer", scrollOffsetTop);
  //     focusAndSetCursor(rangeIx, cursorPosition);
  //   }
  // }, []);

  /* --- KeyPress Handlers --- */
  const onPressEnter = ({
    rangeIndex,
    updatedRangeWords,
    selectedWordIndex,
    wordCharIndex,
    event,
    moveWordsToNextRange,
  }: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    event: React.KeyboardEvent;
    moveWordsToNextRange?: boolean;
  }) => {
    if (event.shiftKey) {
      // Breaking to multiline
      scrollInto(`range-${rangeIndex}`, "editorContainer");
    } else {
      // Breaking range
      event.preventDefault();
      event.stopPropagation();

      const nextRangeId = breakRange(
        rangeIndex,
        updatedRangeWords,
        selectedWordIndex,
        wordCharIndex,
        event,
        moveWordsToNextRange
      );

      // scrollInto(`range-${rangeIndex + 1}`, "editorContainer");
      scrollToRange({ rangeId: nextRangeId });
    }
  };

  // const onPressBackspace = ({
  //   rangeIndex,
  //   updatedRangeWords,
  //   selectedWordIndex,
  //   wordCharIndex,
  //   event,
  // }: {
  //   rangeIndex: number;
  //   updatedRangeWords: Word[];
  //   selectedWordIndex: number;
  //   wordCharIndex: number;
  //   event: React.KeyboardEvent;
  // }) => {
  //   breakRange(
  //     rangeIndex,
  //     updatedRangeWords,
  //     selectedWordIndex,
  //     wordCharIndex,
  //     event
  //   );

  //   scrollInto(`range-${rangeIndex + 1}`);
  // };
  /* --- KeyPress Handlers --- */

  // -Protocol Range Handlers

  const breakLineInsideRange = (rangeIndex: number) => {
    //TODO: This is useless. check what is shift+enter do
    scrollInto(`range-${rangeIndex}`, "editorContainer");
  };

  const breakRange = (
    rangeIndex: number,
    updatedRangeWords: Word[],
    selectedWordIndex: number,
    wordCharIndex: number,
    event: React.KeyboardEvent,
    moveWordsToNextRange = false
  ) => {
    const { selectionStart, selectionEnd } =
      event.target as HTMLTextAreaElement;
    const textLength = _.get(event.target, "textLength");
    const isCarretInEndOfWord =
      updatedRangeWords[selectedWordIndex].word.length === wordCharIndex;
    const breakIndex = isCarretInEndOfWord
      ? selectedWordIndex + 1
      : selectedWordIndex;

    if (selectionStart === textLength || selectionStart === 0) return;

    const wordToBreak =
      wordCharIndex > 0 &&
      !isCarretInEndOfWord &&
      _.clone(updatedRangeWords[breakIndex]);

    const firstPartWords = _.clone(updatedRangeWords);
    const secondPartWords = firstPartWords.splice(breakIndex);

    if (wordToBreak) {
      wordToBreak.word = wordToBreak.word.slice(0, wordCharIndex);
      secondPartWords[0].word = secondPartWords[0].word.slice(
        wordCharIndex,
        secondPartWords[0].word.length
      );
      firstPartWords.push(wordToBreak);
    }

    const updatedRanges = _.clone(ranges);

    const firstPartRange = {
      ...ranges[rangeIndex],
      id: generateId("r_"),
      words: firstPartWords,
      et: firstPartWords[firstPartWords.length - 1].end_time,
    };

    let nextRangeId;

    if (event && moveWordsToNextRange) {
      const secondPartRange = {
        ...ranges[rangeIndex],
        id: generateId("_r"),
        st: firstPartRange.et,
        words: EditorService.reIndexWords(secondPartWords, rangeIndex),
      };

      nextRangeId = secondPartRange.id;

      updatedRanges.splice(rangeIndex, 1, firstPartRange, secondPartRange);
    } else if (updatedRanges[rangeIndex + 1]) {
      updatedRanges[rangeIndex] = firstPartRange;
      updatedRanges[rangeIndex + 1] = {
        ...ranges[rangeIndex + 1],
        id: generateId("_r"),
        st: secondPartWords[0].start_time,
        words: EditorService.reIndexWords(
          _.concat(secondPartWords, ranges[rangeIndex + 1].words),
          rangeIndex + 1
        ),
      };

      nextRangeId = updatedRanges[rangeIndex + 1].id;
    }

    // if (timestampsEnterMethod === "player") {
    //   const _currentTime = Number(currentTime.toFixed(1));
    //   const firstRange = updatedRanges[rangeIndex];
    //   const secondRange = updatedRanges[rangeIndex + 1];

    //   firstRange.et = _currentTime;
    //   firstRange.words[firstRange.words.length - 1].end_time = _currentTime;

    //   secondRange.st = _currentTime;
    //   secondRange.words[0].start_time = _currentTime;
    // }

    updateRanges(updatedRanges, rangeIndex);

    return nextRangeId;
  };

  const mergeRange = (
    rangeIndex: number,
    updatedRangeWords: Word[],
    mergeWithNextRange = false
  ) => {
    const updatedRanges = _.clone(ranges);

    const firstRange =
      updatedRanges[mergeWithNextRange ? rangeIndex : rangeIndex - 1];
    const secondRange =
      updatedRanges[mergeWithNextRange ? rangeIndex + 1 : rangeIndex];

    secondRange.words = mergeWithNextRange // Check what is that
      ? secondRange.words
      : updatedRangeWords;
    const rangeToMergeInto = firstRange;
    const rangeToMergeIntoLength = rangeToMergeInto.words
      .map((w) => w.word)
      .join(" ").length;

    rangeToMergeInto.words = EditorService.reIndexWords(
      _.concat(rangeToMergeInto.words, secondRange.words),
      rangeIndex
    );

    rangeToMergeInto.et = secondRange.et;

    updatedRanges.splice(_.indexOf(updatedRanges, secondRange), 1);

    updateRanges(updatedRanges, rangeIndex);

    if (mergeWithNextRange) {
      const currentRangeLength = updatedRangeWords
        .map((w) => w.word)
        .join(" ").length;
      setTimeout(() => focusAndSetCursor(rangeIndex, currentRangeLength), 0);
    } else {
      setTimeout(
        () => focusAndSetCursor(rangeIndex - 1, rangeToMergeIntoLength),
        0
      );
    }
  };

  const deleteRange = (rangeIndex: number) => {
    const updatedRanges = _.clone(ranges);

    updatedRanges.splice(rangeIndex, 1);

    updateRanges(updatedRanges, rangeIndex);

    focusAndSetCursor(rangeIndex + 1);
  };

  const updateRangeWords = (rangeIndex: number, rangeWords: Word[]) => {
    const updatedRanges = _.clone(ranges);
    const updatedRange = { ...ranges[rangeIndex], words: rangeWords };
    _.set(updatedRanges, `[${rangeIndex}]`, updatedRange);
    updateRanges(updatedRanges, rangeIndex);
  };
  // Subtitles Range Handlers

  const rangeRenderer = ({
    key,
    index,
    isScrolling,
    isVisible,
    style,
  }: {
    key: string;
    index: number;
    isScrolling: boolean;
    isVisible: boolean;
    style: any;
  }) => {
    const range = ranges[index];
    return (
      <div key={key} style={style}>
        <RangeSubtitles
          job={job}
          ranges={ranges}
          range={range as SubtitlesRange}
          rangeIndex={index}
          updateRangeWords={updateRangeWords}
          rangesCount={ranges.length}
          onPressEnter={onPressEnter}
          breakLineInsideRange={breakLineInsideRange}
          mergeRange={mergeRange}
          deleteRange={deleteRange}
          updateRangeTimes={updateRangeTimes}
          isPassed={index < currentPlayingRangeIndex}
          isCurrentPlayingRange={index === currentPlayingRangeIndex}
          direction={editorDirection}
          disabled={isDisabled}
        />
      </div>
    );
  };

  return (
    <div className="SubtitlesEditor">
      <div id="editorContainer" className="editorRanges subtitlesRanges">
        {featureFlags?.experimentalSubtitlesEditor ? (
          <div style={{ width: "100%", height: "100%" }}>
            <div className="experimental">Experimental</div>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  width={width}
                  height={height}
                  rowCount={ranges.length}
                  rowHeight={90}
                  rowRenderer={rangeRenderer}
                />
              )}
            </AutoSizer>
          </div>
        ) : (
          ranges.map((range, rangeIndex) => (
            <div
              id={`rangeContainer-${rangeIndex}`}
              key={
                range.id ||
                `${range.words
                  .slice(0, 10)
                  .map((w) => w.word)
                  .join("")}-${range.st}-${range.et}`
              }
            >
              <LazyLoad
                height={100}
                offset={
                  // Determain divs height by its word count
                  ((range.words && range.words.length * 1.3) || 400) + 1000
                }
                scrollContainer="#editorContainer"
                unmountIfInvisible={true}
              >
                <RangeSubtitles
                  job={job}
                  ranges={ranges}
                  range={range as SubtitlesRange}
                  rangeIndex={rangeIndex}
                  updateRangeWords={updateRangeWords}
                  rangesCount={ranges.length}
                  onPressEnter={onPressEnter}
                  breakLineInsideRange={breakLineInsideRange}
                  mergeRange={mergeRange}
                  deleteRange={deleteRange}
                  updateRangeTimes={updateRangeTimes}
                  isPassed={rangeIndex < currentPlayingRangeIndex}
                  setFocusedRangeIndex={setFocusedRangeIndex}
                  isCurrentPlayingRange={
                    rangeIndex === currentPlayingRangeIndex
                  }
                  direction={editorDirection}
                  disabled={isDisabled}
                />
              </LazyLoad>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Subtitles;
