import React from "react";
import { RenderElementProps } from "slate-react";
import RangeSpeaker from "../Ranges/RangeSpeaker";
import {
  AnnotationRangeElement,
  SpeakerRangeElement,
} from "@sumit-platforms/types";
import RangeAnnotation from "../RangeAnnotation/RangeAnnotation";
import { UpdateTcOffsetFn } from "../../editor";

type InternalProps = {
  updateTcOffset: UpdateTcOffsetFn;
  hideTimecode?: boolean;
  handleBlur?: (rangeIndex?: number) => void;
};
const Range = ({
  attributes,
  children,
  element,
  updateTcOffset,
  hideTimecode,
  handleBlur,
}: RenderElementProps & InternalProps) => {
  switch (element.type) {
    case "speakersRange": {
      const ele = element as SpeakerRangeElement;
      return (
        <RangeSpeaker
          attributes={attributes}
          element={ele}
          hideTimecode={hideTimecode}
          updateTcOffset={updateTcOffset}
          handleBlur={handleBlur}
        >
          {children}
        </RangeSpeaker>
      );
    }
    case "annotationRange": {
      const ele = element as AnnotationRangeElement;
      return (
        <RangeAnnotation element={ele} annotation={ele.annotation}>
          {children}
        </RangeAnnotation>
      );
    }

    default:
      return <p {...attributes}>{element.type}</p>;
  }
};

export default Range;
