import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useState,
} from "react";
import _ from "lodash";
import { Shortcut, UserSettings } from "@sumit-platforms/types";
import { keyboardShortcuts } from "../constants/keyboardShortcuts";

export interface ShortcutsContextProps {
  userShortcuts: Shortcut[];
  initShortcuts: (userSettings?: UserSettings) => void;
}

export const ShortcutsContext = createContext<ShortcutsContextProps | null>(
  null
);

export const ShortcutsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userShortcuts, setUserShortcuts] = useState(keyboardShortcuts);

  const initShortcuts = useCallback((userSettings?: UserSettings) => {
    if (!userSettings?.keyboardShortcuts) return;
    const mergedShortcuts = keyboardShortcuts.map((defaultShortcut) => {
      const userShortcut = userSettings.keyboardShortcuts?.find(
        (s) => s?.action === defaultShortcut.action
      );
      if (userShortcut) {
        return {
          ...defaultShortcut,
          ..._.pick(userShortcut, ["keys", "action"]),
        };
      }
      return defaultShortcut;
    });
    setUserShortcuts(mergedShortcuts);
  }, []);

  return (
    <ShortcutsContext.Provider
      value={{ userShortcuts, initShortcuts } as ShortcutsContextProps}
    >
      {children}
    </ShortcutsContext.Provider>
  );
};
