import axios from "axios";
import {
  EnrichClientMetadataField,
  ExtractField,
  ExtractQuery,
  ExtractResult,
} from "@sumit-platforms/types";

const ExtractService = ({ config }: { config: any }) => {
  const extractEndpoint = `${config.server.host}/${config.server.extract}`;

  const getClientMetadata = async (
    idClient: number,
    field: EnrichClientMetadataField
  ) => {
    const result = await axios.get<string[]>(
      `${extractEndpoint}/clientMetadata`,
      {
        params: {
          idClient,
          field,
        },
      }
    );
    if (result.status !== 200) throw result.statusText;
    return result.data;
  };

  const getFields = async (): Promise<ExtractField[]> => {
    try {
      const result = await axios.get<ExtractField[]>(
        `${extractEndpoint}/fields`
      );
      if (result.status !== 200) throw result.statusText;
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const runExtractQuery = async ({
    extractQuery,
    idClient,
    page,
  }: {
    extractQuery: ExtractQuery;
    idClient: number;
    page?: number;
  }): Promise<ExtractResult[]> => {
    try {
      const queryExtractIndexEndpoint = `${extractEndpoint}/runExtractQuery`;
      const searchResult = await axios.post<ExtractResult[]>(
        queryExtractIndexEndpoint,
        {
          extractQuery,
          idClient,
          page,
        }
      );
      return searchResult.data;
    } catch (err) {
      console.error("Failed to query extract", err);
      throw err;
    }
  };

  const extractQueryFeedback = async ({
    queryId,
    resultId,
    rating,
  }: {
    queryId: string;
    resultId: string;
    rating: number;
  }): Promise<void> => {
    try {
      const queryExtractFeedbackEndpoint = `${extractEndpoint}/extractQueryFeedback`;
      await axios.post(queryExtractFeedbackEndpoint, {
        queryId,
        resultId,
        rating,
      });
    } catch (err) {
      console.error("Failed to feedback extract", err);
      throw err;
    }
  };

  return {
    getFields,
    getClientMetadata,
    runExtractQuery,
    extractQueryFeedback,
  };
};

export default ExtractService;
