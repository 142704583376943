import _ from "lodash";
import { AR, EN, HE } from "@sumit-platforms/common-assets/i18n";
import * as EN_b2b from "./b2b-EN.json";
import * as HE_b2b from "./b2b-HE.json";
import * as AR_b2b from "./b2b-AR.json";

const resources = {
  en: {
    translation: _.merge(EN, EN_b2b),
  },
  he: {
    translation: _.merge(HE, HE_b2b),
  },
  ar: {
    translation: _.merge(AR, AR_b2b),
  },
};

export default resources;
