import React, { FC, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

import EditorService from "./services/EditorService";
//
// import { Word } from "../../models";
// import { JobData } from "../../models/job";
// import { SubtitlesTranslationRange } from "../../models/range";
// import { Action } from "../../models/editor";
import {
  Word,
  JobData,
  SubtitlesTranslationRange,
  JobWithData,
  EditorFeatureFlags,
} from "@sumit-platforms/types";
import { EditorAction } from "./types/EditorAction";
import { generateId } from "./utils/generateId";
import {
  focusAndSetCursor,
  scrollInto,
  scrollToOffset,
} from "./utils/focusAndScroll";
import RangeSubtitlesTranslate from "./components/Ranges/RangeSubtitlesTranslate";

import "./SubtitlesTranslation.scss";

const logger = console;

interface Props {
  job: JobWithData;
  ranges: SubtitlesTranslationRange[];
  updateRanges: (
    ranges: SubtitlesTranslationRange[],
    rangeIndex?: number
  ) => void;
  currentPlayingRangeIndex: number;
  focusedRangeIndex?: number;
  setFocusedRangeIndex?: (rangeIx: number) => void;
  currentTime: number;
  editorDirection: "rtl" | "ltr";
  addActions: (actions: EditorAction[]) => void;
  isDisabled?: boolean;
  featureFlags?: EditorFeatureFlags;
}

const SubtitlesTranslation: FC<Props> = ({
  job,
  ranges,
  updateRanges,
  currentPlayingRangeIndex,
  focusedRangeIndex,
  setFocusedRangeIndex,
  currentTime,
  editorDirection,
  addActions,
  isDisabled,
  featureFlags,
}): JSX.Element => {
  const { t } = useTranslation();

  const breakRange = async (
    rangeIndex: number,
    updatedRangeWords: Word[],
    selectedWordIndex: number,
    wordCharIndex: number,
    event: React.KeyboardEvent,
    moveWordsToNextRange: boolean
  ) => {
    const { selectionStart, selectionEnd } =
      event.target as HTMLTextAreaElement;
    const textLength = _.get(event.target, "textLength");
    const isCarretInEndOfWord =
      updatedRangeWords[selectedWordIndex].word.length === wordCharIndex;
    const breakIndex = isCarretInEndOfWord
      ? selectedWordIndex + 1
      : selectedWordIndex;

    if (selectionStart === textLength || selectionStart === 0) return;

    const wordToBreak =
      wordCharIndex > 0 &&
      !isCarretInEndOfWord &&
      _.clone(updatedRangeWords[breakIndex]);

    const firstPartWords = _.clone(updatedRangeWords);
    const secondPartWords = firstPartWords.splice(breakIndex);

    if (wordToBreak) {
      wordToBreak.word = wordToBreak.word.slice(0, wordCharIndex);
      secondPartWords[0].word = secondPartWords[0].word.slice(
        wordCharIndex,
        secondPartWords[0].word.length
      );
      firstPartWords.push(wordToBreak);
    }

    const updatedRanges = _.clone(ranges);

    const firstPartRange = {
      ...ranges[rangeIndex],
      id: generateId("r_"),
      words: firstPartWords,
      et: firstPartWords[firstPartWords.length - 1].end_time,
    };

    if (event && moveWordsToNextRange) {
      const secondPartRange = {
        ...ranges[rangeIndex],
        id: generateId("_r"),
        st: firstPartRange.et,
        words: EditorService.reIndexWords(secondPartWords, rangeIndex),
      };

      updatedRanges.splice(rangeIndex, 1, firstPartRange, secondPartRange);
    } else if (updatedRanges[rangeIndex + 1]) {
      updatedRanges[rangeIndex] = firstPartRange;
      updatedRanges[rangeIndex + 1] = {
        ...ranges[rangeIndex + 1],
        st: secondPartWords[0].start_time,
        words: EditorService.reIndexWords(
          _.concat(secondPartWords, ranges[rangeIndex + 1].words),
          rangeIndex + 1
        ),
      };
    }

    updateRanges(updatedRanges, rangeIndex);

    setTimeout(() => focusAndSetCursor(rangeIndex + 1), 0);
  };

  const mergeRange = (
    rangeIndex: number,
    updatedRangeWords: Word[],
    mergeWithNextRange = false
  ) => {
    const updatedRanges = _.clone(ranges);

    const firstRange =
      updatedRanges[mergeWithNextRange ? rangeIndex : rangeIndex - 1];
    const secondRange =
      updatedRanges[mergeWithNextRange ? rangeIndex + 1 : rangeIndex];

    const rangeToMergeInto = firstRange;
    const rangeToMergeIntoLength = rangeToMergeInto.words
      .map((w) => w.word)
      .join(" ").length;

    rangeToMergeInto.words = EditorService.reIndexWords(
      _.concat(rangeToMergeInto.words, secondRange.words),
      rangeIndex
    );

    rangeToMergeInto.autoTranslationWords = _.concat(
      rangeToMergeInto.autoTranslationWords,
      secondRange.autoTranslationWords
    );

    rangeToMergeInto.words = _.concat(
      rangeToMergeInto.autoTranslationWords,
      secondRange.autoTranslationWords
    );

    rangeToMergeInto.sourceWords = _.concat(
      rangeToMergeInto.sourceWords,
      secondRange.sourceWords
    );

    rangeToMergeInto.et = secondRange.et;
    rangeToMergeInto.id = generateId("r_");

    updatedRanges.splice(_.indexOf(updatedRanges, secondRange), 1);

    updateRanges(updatedRanges, rangeIndex);

    if (mergeWithNextRange) {
      const currentRangeLength = updatedRangeWords
        .map((w) => w.word)
        .join(" ").length;
      setTimeout(() => focusAndSetCursor(rangeIndex, currentRangeLength), 0);
    } else {
      setTimeout(
        () => focusAndSetCursor(rangeIndex - 1, rangeToMergeIntoLength),
        0
      );
    }
  };

  const deleteRange = (rangeIndex: number) => {
    const updatedRanges = _.clone(ranges);

    updatedRanges.splice(rangeIndex, 1);

    updateRanges(updatedRanges, rangeIndex);

    setTimeout(() => {
      // here we must use setTimeout to set focus, unlike Subtitles.tsx. worth check.
      focusAndSetCursor(rangeIndex);
    }, 0);
  };

  // KeyPress Handlers
  const onPressEnter = ({
    rangeIndex,
    updatedRangeWords,
    selectedWordIndex,
    wordCharIndex,
    event,
    moveWordsToNextRange,
  }: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    event: React.KeyboardEvent;
    moveWordsToNextRange: boolean;
  }) => {
    // Breaking range
    focusAndSetCursor(rangeIndex + 1, 0);

    event.preventDefault();
    event.stopPropagation();

    breakRange(
      rangeIndex,
      updatedRangeWords,
      selectedWordIndex,
      wordCharIndex,
      event,
      moveWordsToNextRange
    );
    scrollInto(`range-${rangeIndex + 1}`, "editorContainer");
  };
  // KeyPress Handlers

  const updateRangeWords = (rangeIndex: number, rangeWords: Word[]) => {
    const updatedRanges = _.clone(ranges);
    const updatedRange = { ...ranges[rangeIndex], words: rangeWords };
    _.set(updatedRanges, `[${rangeIndex}]`, updatedRange);

    updateRanges(updatedRanges, rangeIndex);
  };

  return (
    <div className="SubtitlesTranslationEditor">
      <div className="titles">
        <div className="title">
          <div className="lang">{t(job.outputLanguage[0])}:</div>
          <div className="rangesType">{`(${t("target")})`}</div>
        </div>
        <div className="title">
          <div className="lang">{t(job.inputLanguage[0])}:</div>
          <div className="rangesType">{`(${t("source")})`}</div>
        </div>
      </div>
      <div
        id="editorContainer"
        className="editorRanges subtitlesTranlationRanges"
      >
        {ranges.map((range, rangeIndex) => (
          <LazyLoad
            height={100}
            offset={
              // Determain divs height by its word count
              ((range.sourceWords && range.sourceWords.length * 1.3) || 400) +
              1000
            }
            scrollContainer="#editorContainer"
            unmountIfInvisible={rangeIndex !== currentPlayingRangeIndex}
            key={
              range.id ||
              `${rangeIndex}_${range.sourceWords
                .map((w) => w.word)
                .join("")
                .slice(0, 10)}`
            }
          >
            <RangeSubtitlesTranslate
              job={job}
              ranges={ranges}
              range={range}
              updateRangeWords={updateRangeWords}
              onPressEnter={onPressEnter}
              mergeRange={mergeRange}
              deleteRange={deleteRange}
              isCurrentPlayingRange={rangeIndex === currentPlayingRangeIndex}
              isPassed={rangeIndex < currentPlayingRangeIndex}
              rangeIndex={rangeIndex}
              rangesCount={ranges.length}
              direction={editorDirection}
              disabled={isDisabled}
              featureFlags={featureFlags}
            />
          </LazyLoad>
        ))}
      </div>
    </div>
  );
};

export default SubtitlesTranslation;
