import * as React from "react";
import { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import stc from "string-to-color";
import { FilterOptionsState } from "@mui/material";
import { Direction } from "@sumit-platforms/types";
import { Box } from "@mui/system";

import "./CreateableAutocomplete.scss";

export interface CreatableAutocompleteOption {
  inputValue: string;
  title: string;
  triggerNewValue?: boolean;
}

type CreatableAutocompleteProps = {
  options: CreatableAutocompleteOption[];
  value: CreatableAutocompleteOption | null;
  setValue: (option: CreatableAutocompleteOption) => void;
  onNewValueAdded: (option: CreatableAutocompleteOption) => void;
  paintStrings?: boolean;
  placeholder?: string;
  sx?: React.CSSProperties;
  direction: Direction;
  focusOnInit?: boolean;
  onBeforeFocus?: () => void;
  onUnmount?: () => void;
};

const filter = (
  options: CreatableAutocompleteOption[],
  value: FilterOptionsState<CreatableAutocompleteOption>
) => {
  const sorted = options.sort((a, b) =>
    a.inputValue.localeCompare(b.inputValue)
  );
  const filteredOptions = sorted.filter((option) =>
    option.inputValue.startsWith(value.inputValue)
  );
  return filteredOptions;
};

export const CreatableAutocomplete = ({
  options,
  value,
  setValue,
  paintStrings,
  onNewValueAdded,
  placeholder,
  sx,
  focusOnInit = false,
  direction,
  onBeforeFocus,
  onUnmount,
}: CreatableAutocompleteProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const highlightedOption = useRef<CreatableAutocompleteOption | null>(null);

  useEffect(() => {
    if (focusOnInit) {
      // focusOnInit can be changed with control+d via setNodes function. check open_speakers shortcut
      onBeforeFocus && onBeforeFocus();
      setTimeout(() => {
        ref.current?.click();
        ref.current?.focus();
      }, 0);
    }
  }, [focusOnInit]);

  useEffect(() => {
    return () => {
      if (focusOnInit && onUnmount) {
        onUnmount();
      }
    };
  }, []);

  const onChange = (
    event: any,
    newValue: CreatableAutocompleteOption | null
  ) => {
    const formattedNewValue: CreatableAutocompleteOption = {
      ...newValue,
      inputValue: newValue?.inputValue.trim() || "",
      title: newValue?.title.trim() || "",
    };
    if (typeof formattedNewValue === "string") {
      setValue({
        title: formattedNewValue,
        inputValue: formattedNewValue,
      });
    } else if (formattedNewValue && formattedNewValue.inputValue) {
      setValue(formattedNewValue);
    }

    if (formattedNewValue && formattedNewValue.triggerNewValue) {
      onNewValueAdded(formattedNewValue);
    }
  };

  const filterOptions = (
    options: CreatableAutocompleteOption[],
    params: FilterOptionsState<CreatableAutocompleteOption>
  ) => {
    const filtered = filter(options, params);

    const { inputValue } = params;

    const isExisting = options.some((option) => inputValue === option.title);
    const trimmedValue = inputValue.trim();
    if (trimmedValue !== "" && !isExisting) {
      filtered.push({
        inputValue: trimmedValue,
        title: `${t("add")} "${inputValue}"`,
        triggerNewValue: true,
      });
    }

    return filtered;
  };

  const getOptionLabel = (option: CreatableAutocompleteOption) => {
    if (typeof option === "string") {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.title;
  };
  const renderOption = (
    props: React.HTMLAttributes<any>,
    option: CreatableAutocompleteOption
  ) => {
    const { key, ...optionProps } = props as any;
    return (
      <li key={key} style={{ direction: direction }} {...optionProps}>
        {option.title}
      </li>
    );
  };

  const changeToCurrentInputValue = (event: any, params: any) => {
    const filteredOptions = filter(options, {
      inputValue: params.inputProps?.value?.trim(),
      getOptionLabel,
    });

    const shouldTriggerNewValue =
      highlightedOption.current?.triggerNewValue ||
      filteredOptions.length === 0;
    if (!shouldTriggerNewValue) return;

    // Means the user is typed something and either:
    // the user focus on the "Add new..." option
    // the user has no other options but "Add new..." option
    const newOption = {
      inputValue: params.inputProps.value as string,
      title: params.inputProps.value as string,
    };
    onChange(event, newOption);
    onNewValueAdded(newOption);
    event.preventDefault();
  };

  return (
    <Autocomplete
      sx={sx}
      value={value}
      onChange={onChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      openOnFocus
      autoFocus={focusOnInit}
      clearIcon={false}
      handleHomeEndKeys
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      isOptionEqualToValue={(option, value) =>
        option.inputValue === value.inputValue
      }
      autoHighlight={true}
      onHighlightChange={(event, option) => {
        highlightedOption.current = option;
      }}
      renderInput={(params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              height: "100%",
            }}
          >
            <TextField
              ref={ref}
              placeholder={value?.title || placeholder}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
              }}
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                input: {
                  color: paintStrings
                    ? stc(value?.inputValue.trim())
                    : "inherit",
                },
              }}
              onKeyDown={(event) => {
                if (
                  (event.key === "Enter" || event.key === "Tab") &&
                  params.inputProps.value
                ) {
                  changeToCurrentInputValue(event, params);
                }
              }}
            />
          </Box>
        );
      }}
    />
  );
};
