import React from "react";
import { MarkWordMetadata } from "@sumit-platforms/types";
import { VirtualizedList } from "@sumit-platforms/ui-bazar";
import FindAndReplaceHighlightItem from "./FindAndReplaceHighlightItem";
import { ListItemButton } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import FindAndReplacePlaceholderText from "./FindAndReplacePlaceholderText";

interface HighlightsListProps {
  highlights?: MarkWordMetadata[] | null;
  find: string;
  selectedHighlight: MarkWordMetadata | null;
  onClick: (word: MarkWordMetadata, idx: number) => void;
  selectedIndex: number | null;
  onItemHover?: (word: MarkWordMetadata) => void;
}
const maxHeight = 300;
const itemHeight = 55;

const FindAndReplaceHighlightsList = ({
  highlights,
  find,
  onClick,
  selectedHighlight,
  onItemHover,
  selectedIndex,
}: HighlightsListProps) => {
  const { t } = useTranslation();
  return highlights ? (
    highlights?.length ? (
      <>
        <FindAndReplacePlaceholderText
          highlights={highlights}
          selectedIndex={selectedIndex}
        />
        <VirtualizedList
          items={highlights}
          maxHeight={maxHeight}
          itemCount={highlights.length}
          itemHeight={itemHeight}
          className={"FindAndReplaceHighlightsList"}
          render={(word, idx) => {
            return (
              <FindAndReplaceHighlightItem
                term={find}
                word={word}
                selectedWord={selectedHighlight}
                maxLength={20}
                onClick={() => onClick(word, idx)}
                onHover={(word) => onItemHover && onItemHover(word)}
              />
            );
          }}
        />
      </>
    ) : (
      <ListItemButton
        disabled
        dir={"ltr"}
        sx={{ margin: "auto 0", padding: "12px 0", fontSize: "14px" }}
      >
        {t("no_options")}
      </ListItemButton>
    )
  ) : null;
};

export default FindAndReplaceHighlightsList;
