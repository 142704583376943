import { JobStatus, JobTranslationType } from "../constants/statuses";
import { Client } from "./Client";
import { JobCustomMetadata, JobTypeMeta, JobTypes } from "./JobTypes";
import { Tag } from "./Tag";
import { Attachment, AttachmentType } from "./Attachment";
import { Media } from "./Media";
import { AssignedUser, User } from "./User";
import { JobData } from "../Job/JobData";
import { attachmentsMimeTypes, mediaMimeTypes } from "../constants/mimetypes";
import { UsersJobs } from "./UsersJobs";
import { CommunityPaymentStatus } from "./CommunityPayment";
import { Languages } from "../constants/languages";
import { JobTranslation } from "./JobTranslation";
import { ValidationPreset } from "./ValidationPreset";
import { JobBonus } from "./JobBonus";
import { HTMLInputTypeAttribute } from "react";
import { JobSplit, JobSplitType } from "./JobSplit";
import { JobInvoice } from "./Invoices";
import { IndexSearch } from "../Algo/enrichIndex";

interface Job {
  idJob: number;
  idJobV2: string;
  projectName: string;
  name: string;
  status: JobStatus;
  type: JobTypeMeta;
  inputLanguage: Languages[];
  outputLanguage: Languages[];
  translation: JobTranslationType;
  delivery: Date;
  transcribeDelivery: Date;
  qcPrice: number;
  transcribePrice: number;
  duration: number;
  price?: number; // legacy, removed once v2 is shut
  client: Client;
  tcOffsets: number[][];
  transcriptionSource: JobTranscriptionSource;
  user: User;
  note?: string;
  data?: JobData;
  media?: Media[];
  attachments?: Attachment[];
  tags?: Tag[];
  assignments?: UsersJobs[];
  idClient?: number;
  idUser?: number;
  idValidationPreset?: number | null;
  autoTranscriptDelivery?: JobAutoTranscriptDeliveryOptions | null;
  sttModel?: string;
  assignedTo?: AssignedUser[];
  userJob?: UsersJobs;
  usersJobs?: UsersJobs[];
  activeAssignment?: UsersJobs;
  jobsTranslations?: JobTranslation[];
  jobTranslation?: JobTranslation;
  sttProgress?: number;
  validationPreset?: ValidationPreset;
  formattedValidation?: any;
  jobBonus?: JobBonus;
  customMetadata: JobCustomMetadata;
  splitType?: JobSplitType | null;
  jobSplit?: JobSplit | null;
  jobSplits?: JobSplit[];
  kitRecordId?: string;
  jobInvoice?: JobInvoice;
  deliveryPages?: number;
  representative?: boolean;
  doneAt?: Date | null;
  createdAt: Date;
  indexSearch?: IndexSearch[];
}

type CreateNewJobsResponse = {
  successUploads: number[];
  successJobs: number[];
};

interface JobFilterOptions {
  idJob?: number[] | number;
  status?: string[];
  inputLanguage?: string[];
  outputLanguage?: string[];
  speakerIds?: number[];
  projectName?: string[];
  type?: string[];
  userIds?: number[];
  durationStart?: number;
  durationEnd?: number;
  deliveryStart?: Date;
  deliveryEnd?: Date;
  uploadedStart?: Date;
  uploadedEnd?: Date;
  clientIds?: number[];
  tagIds?: number[];
  noSourceTranslationJobs?: boolean;
  noSplitsJobs?: boolean;
  idUser?: number | string;
  paymentIds?: number[];
  invoiceIds?: string[];
  split?: ("parent" | "child")[];
  uploaders?: number[];
}

interface JobQueryMetaData {
  maxDuration: number;
  projectNames: string[];
  clients: Pick<Client, "idClient" | "name">[];
  communityMembers: Pick<User, "idUser" | "firstName" | "lastName">[];
  invoices: {
    idInvoice: number;
    invoiceNumber: string;
  }[];
  users: Pick<User, "idUser" | "firstName" | "lastName">[];
}

interface JobQueryResponse {
  jobs: Job[];
  totalJobs: number;
  timestamp?: number;
}

type SpreadsheetJob = {
  media: {
    duration: number;
    mimetype: (typeof mediaMimeTypes)[number];
    name: string;
    path: string;
    provider: "SHEET";
    size: number;
    url: string;
  }[];
  attachments?: [
    {
      mimetype: (typeof attachmentsMimeTypes)[number];
      name: string;
      path: string;
      type: AttachmentType;
      url: string;
      size: number;
    }
  ];
  name: string;
  idClient: number;
  idUser: number;
  projectName: string;
  jobType: keyof JobTypes;
  inputLang: Languages[];
  outputLang: Languages[];
  transcript: string;
  deliveryDate: Date;
  autoTranscriptionDelivery?: boolean;
};

type KitJob = {
  id: string;
  kit_id: string;
  media: {
    wav_files: string[];
    bucket_name: string;
    samplerate: number;
    channels: number;
  };
  input_lang: string;
  output_lang: string[];
  duration: number;
  offset: number;
  name?: string;
  part_ix: number;
  last?: true;
};

enum JobAutoTranscriptDeliveryOptions {
  requested = "REQUESTED",
  delivered = "DELIVERED",
  rejected = "REJECTED",
}

enum JobTranscriptionSource {
  STT = "STT",
  SOURCE_FILE = "SOURCE_FILE",
  SKIP_STT = "SKIP_STT",
}

enum AssignStatus {
  pending = "PENDING",
  accepted = "ACCEPTED",
  rejected = "REJECTED",
  removed = "REMOVED",
  auto_accepted = "AUTO_ACCEPTED",
  resigned = "RESIGNED",
}

const NotActiveAssignmentStatuses = [
  AssignStatus.rejected,
  AssignStatus.removed,
  AssignStatus.resigned,
];

const ActiveAssignmentStatuses = [
  AssignStatus.pending,
  AssignStatus.accepted,
  AssignStatus.auto_accepted,
];

enum AssignMethod {
  request = "REQUEST",
  assign = "ASSIGN",
  retake = "RETAKE",
}

enum AssignmentTask {
  transcriber = "TRANSCRIBER",
  qc = "QC",
}

enum TaskStatus {
  pending = "PENDING",
  work = "WORK",
  resign = "RESIGN",
  done = "DONE",
  stopped = "STOPPED",
}

type JobCommunityMembers = {
  transcriber?: {
    user: AssignedUser;
    method: string;
  };
  qc?: {
    user: AssignedUser;
    method: string;
  };
};

type JobWithPayment = Job & {
  idCommunityPayment: number | string;
  paymentStatus: CommunityPaymentStatus;
  task: AssignmentTask;
  payment: number;
  paymentDate: Date;
  paymentDescription: string;
  isBonus: boolean;
};

type Assignment = {
  idUsersJobs: number;
  idUser: number;
  idJob: number;
  task: AssignmentTask;
  taskStatus: TaskStatus;
  assignMethod: AssignMethod;
  assignStatus: AssignStatus;
  assignedBy: number;
  assignedAt: Date;
  acceptedBy: number;
  acceptedAt: Date;
  rejectedBy: number;
  rejectedAt: Date;
  removedBy: number;
  removedAt: Date;
  requestedAt: Date;
  resignedAt: Date;
};

type AssignmentRequest = {
  job: Job;
  requests: {
    requestedBy: User;
    assignment: Partial<Assignment>;
  }[];
};

type MyAssignment = {
  job: Job;
  assignment: Assignment;
};

type ClientJobMetadataItem = {
  key: string;
  type: HTMLInputTypeAttribute;
};

type ClientJobMetadata = ClientJobMetadataItem[];

const clientJobMetadataOptions = [
  {
    label: "date",
    value: "date",
  },
  {
    label: "number",
    value: "number",
  },
  {
    label: "text",
    value: "text",
  },
];

const zeroSubtitlesOptions = [
  {
    jobKey: "name",
    label: "name",
  },
  {
    jobKey: "projectName",
    label: "projectName",
  },
  {
    jobKey: "duration",
    label: "duration",
  },
  {
    jobKey: "outputLanguage",
    label: "outputLanguage",
  },
  {
    jobKey: "inputLanguage",
    label: "inputLanguage",
  },
  {
    jobKey: "createdAt",
    label: "createdAt",
  },
  // merge metadata to here
];

type ZeroSubtitleLine = {
  value: keyof Job;
  title?: string;
  separator?: string;
  formatters?: {
    valueFormatter?: (value: string) => string;
    titleFormatter?: (title: string) => string;
  };
};

type ZeroSubtitle = {
  content: ZeroSubtitleLine[];
  endTime: number;
  startTime: number;
  timeFormat: "ms" | "frames";
};

type JobTCOffsets = Job["tcOffsets"];

type EmptySubtitle = {
  startTime?: number;
  endTime?: number;
  timeFormat?: "ms" | "frames";
};

type PriceTypeOption = "total" | "minute";

interface JobUpdateOptions {
  priceType?: PriceTypeOption;
}

export type {
  Job,
  ClientJobMetadata,
  ZeroSubtitleLine,
  ZeroSubtitle,
  EmptySubtitle,
  CreateNewJobsResponse,
  SpreadsheetJob,
  KitJob,
  JobFilterOptions,
  AssignedUser,
  JobQueryMetaData,
  JobQueryResponse,
  JobCommunityMembers,
  JobWithPayment,
  AssignmentRequest,
  MyAssignment,
  Assignment,
  JobTCOffsets,
  ClientJobMetadataItem,
  PriceTypeOption,
  JobUpdateOptions,
};

export {
  JobAutoTranscriptDeliveryOptions,
  JobTranscriptionSource,
  AssignMethod,
  AssignStatus,
  AssignmentTask,
  TaskStatus,
  clientJobMetadataOptions,
  zeroSubtitlesOptions,
  NotActiveAssignmentStatuses,
  ActiveAssignmentStatuses,
};
