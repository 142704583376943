import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/system";
import { useSlateStatic } from "slate-react";
import {
  CustomEditor,
  CustomElement,
  MarkWordMetadata,
} from "@sumit-platforms/types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import EditorService from "../../services/EditorService";
import FindAndReplaceHighlightsList from "./FindAndReplaceHighlightsList";
import FindAndReplaceButtonsContainer from "./FindAndReplaceButtons";
import FindAndReplaceInputs from "./FindAndReplaceInputs";
import { Button } from "@sumit-platforms/ui-bazar";
import { freeze } from "@sumit-platforms/ui-bazar/utils";
import { useRecoilValue } from "recoil";
import { isDisabledState } from "../../store/states";

import "./FindAndReplace.scss";

interface FindAndReplaceProps {
  triggerDecoration: React.Dispatch<React.SetStateAction<string>>;
  onReplaceOne: (markedWord: MarkWordMetadata | null, newText: string) => void;
  onReplaceAll: (
    replaceInput: string,
    findInput: string,
    highlights: MarkWordMetadata[]
  ) => boolean;
  close: () => void;
  onBeforeUnmount?: () => void;
}

const minimumFindInputLengthToTrigger = 3;

export const FindAndReplace = ({
  triggerDecoration,
  onReplaceOne,
  onReplaceAll,
  close,
  onBeforeUnmount,
}: FindAndReplaceProps) => {
  const { t } = useTranslation();
  const editor = useSlateStatic() as CustomEditor;
  const disabled = useRecoilValue(isDisabledState);
  const [findInputDirty, setFindInputDirty] = useState(false);
  const [findValueInEditor, setFindValueInEditor] = useState("");
  const [find, setFind] = useState("");
  const [replace, setReplace] = useState("");
  const [highlights, setHighlights] = useState<MarkWordMetadata[] | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const isLoading = useRef(false);

  const selectedHighlight = useMemo(() => {
    if (selectedIndex !== null && highlights?.length) {
      return highlights[selectedIndex];
    }
    return null;
  }, [highlights, selectedIndex]);

  const replaceOneDisabled = useMemo(
    () => !selectedHighlight || !replace?.length || disabled,
    [selectedHighlight, replace]
  );
  const replaceAllDisabled = useMemo(
    () => !replace?.length || !highlights?.length || disabled,
    [highlights, replace]
  );

  const handleHighlightClick = useCallback(
    (word: MarkWordMetadata, index: number) => {
      setSelectedIndex(index);
      EditorService.clearClassname("highlightFocused");
      EditorService.scrollAndHighlightWord({
        editor,
        word,
      });
    },
    [editor]
  );

  const handleTriggerDecoration = useCallback(
    (val: string) => {
      triggerDecoration(val);
      setFindValueInEditor(val);
    },
    [triggerDecoration, setFindValueInEditor]
  );

  const handleOnMouseLeaveHighlight = useCallback(() => {
    EditorService.clearClassname("highlightFocused");
    if (_.isNumber(selectedIndex) && highlights?.length) {
      EditorService.scrollAndHighlightWord({
        editor,
        word: highlights[selectedIndex],
      });
    }
  }, [editor, highlights, selectedIndex]);

  const onFindInputDirty = useCallback(() => {
    setFindInputDirty(true);
    setHighlights(null);
    EditorService.clearClassname("highlightFocused");
  }, [setFindInputDirty]);

  const calculateHighlights = useCallback(
    (val: string, focus = true) => {
      if (val.length < minimumFindInputLengthToTrigger) {
        onFindInputDirty();
        return;
      }

      if (findInputDirty) setFindInputDirty(false);
      handleTriggerDecoration(val);

      const nodes = Array.from(editor.nodes({ at: [] }));
      const ranges: MarkWordMetadata[] = [];
      nodes.forEach(([node, path]) => {
        const nodeHighlights = EditorService.getHighlightWordsByText({
          search: val,
          node: node as CustomElement,
          path,
        });
        ranges.push(...nodeHighlights);
      });

      setHighlights(ranges);

      const wordToHighlight = ranges?.[0];
      if (wordToHighlight && focus) {
        setSelectedIndex(0);
        freeze(350).then(() => {
          // Bad practice, but we are waiting for the editor to paint
          EditorService.scrollAndHighlightWord({
            editor,
            word: wordToHighlight,
          });
        });
      } else {
        EditorService.clearClassname("highlightFocused");
      }
    },
    [findInputDirty, setFindInputDirty, highlights, handleTriggerDecoration]
  );

  const handleOnFindChange = useCallback((e: any) => {
    const val = e.target?.value || "";

    setFind(val);
  }, []);
  const handleOnReplaceChange = useCallback(
    (e: any) => {
      const val = e.target?.value || "";
      setReplace(val);
    },
    [setReplace]
  );

  const resetState = useCallback(() => {
    setSelectedIndex(null);
    EditorService.clearClassname("highlightFocused");
    triggerDecoration("");
  }, [setSelectedIndex, triggerDecoration]);

  const handleOnReplaceOne = useCallback(async () => {
    isLoading.current = true;
    EditorService.clearClassname("highlightFocused");
    onReplaceOne(selectedHighlight, replace);
    await freeze(350);
    isLoading.current = false;
  }, [onReplaceOne, replace, selectedHighlight]);

  const handleOnReplaceAll = useCallback(() => {
    if (!highlights) return;
    isLoading.current = true;
    const success = onReplaceAll(replace, find?.trim(), highlights);
    if (success) resetState();
    handleTriggerDecoration(find);
    isLoading.current = false;
  }, [
    find,
    highlights,
    onReplaceAll,
    replace,
    resetState,
    handleTriggerDecoration,
  ]);

  const handleOnHighlightItemHover = useCallback(
    (word: MarkWordMetadata) => {
      EditorService.scrollAndHighlightWord({
        editor,
        word,
      });
    },
    [editor]
  );

  useEffect(() => {
    return () => {
      if (onBeforeUnmount) onBeforeUnmount();
      resetState();
    };
  }, []);

  useEffect(() => {
    // This use effect is listening to editor change in order to trigger highlighting
    const { apply } = editor;

    editor.apply = (operation) => {
      apply(operation);

      const changeTextOperations = [
        "insert_text",
        "remove_text",
        "split_node",
        "merge_node",
        "set_node",
      ];
      if (changeTextOperations.includes(operation.type) && !isLoading.current) {
        close();
      } else {
        calculateHighlights(find?.trim(), false);
      }
    };

    return () => {
      editor.apply = apply;
    };
  }, [calculateHighlights, close, editor, find, isLoading]);

  const handleCalculateHighlights = useCallback(() => {
    calculateHighlights(find?.trim());
  }, [calculateHighlights, find]);

  const onKeyDown = useCallback(
    (e: any) => {
      if (e.key === "Enter") {
        handleCalculateHighlights();
      }
    },
    [handleCalculateHighlights]
  );

  return (
    <Box className={"FindAndReplace"} onKeyDown={onKeyDown}>
      <Box className={"inputsAndActions"}>
        <FindAndReplaceInputs
          find={find}
          findInputDirty={findInputDirty}
          onFindChange={handleOnFindChange}
          replace={replace}
          onReplaceChange={handleOnReplaceChange}
        />
        <Box className={"actionsContainer"}>
          <Button
            className={"searchButton"}
            onClick={handleCalculateHighlights}
            disabled={find?.trim()?.length < minimumFindInputLengthToTrigger}
          >
            {t("search")}
          </Button>
          <FindAndReplaceButtonsContainer
            isReplaceOneDisabled={replaceOneDisabled}
            onReplaceOne={handleOnReplaceOne}
            isReplaceAllDisabled={replaceAllDisabled}
            onReplaceAll={handleOnReplaceAll}
          />
        </Box>
      </Box>
      <Box onMouseLeave={handleOnMouseLeaveHighlight}>
        <FindAndReplaceHighlightsList
          selectedIndex={selectedIndex}
          highlights={highlights}
          find={findValueInEditor}
          selectedHighlight={selectedHighlight}
          onClick={handleHighlightClick}
          onItemHover={handleOnHighlightItemHover}
        />
      </Box>
    </Box>
  );
};
