const DEFAULT_RETURN_SIZE = 25;

enum IndexType {
  DOCUMENTS = "documents",
  FRAMES = "frames",
  SENTENCE = "sentence",
  PARAGRAPH = "paragraphs",
}

enum TextualIndexType {
  SENTENCE = "sentence",
  PARAGRAPH = "paragraph",
}

type SearchType =
  | "simple_text"
  | "semantic"
  | "semantic_text"
  | "filter"
  | "filter_range"
  | "boost_ner"
  | "text_analyzed"
  | "exact_text"
  | "txt2img"
  | "fuzziness"
  | "txt2img_clip"
  | "txt2img_llm"
  | "img2vec"
  | "text_substring";

type Operand = "OR" | "AND" | "NOT";

type Field =
  | "speaker.keyword"
  | "creation_time"
  | "sentence"
  | "tags"
  | "clip"
  | "llm4blip"
  | "clip_vec"
  | "blip_llm_vec"
  | "filename.keyword"
  | "paragraph"
  | "job_id"
  | "paragraph_id";

const metadataField: Field[] = ["filename.keyword"];

type RootQuery = {
  query?: string | string[] | number[];
  type: SearchType;
  field: Field | Field[];
  min?: string;
  max?: string;
};
interface IndexSearchField {
  type: Operand;
  query: RootQuery[];
}

interface IndexSearchSegment {
  type: Operand;
  query: (IndexSearchField | RootQuery)[];
}
interface IndexSearchQuery {
  type: Operand;
  query: IndexSearchSegment[];
}

interface IndexSearchQueryPayload {
  index: string[];
  size: number;
  query: IndexSearchQuery;
  user_id?: string;
  session_id?: string;
  expansion?: boolean;
}

type RatingOptions = "bad" | "good" | "relevant";

interface IndexSearchFeedbackPayload {
  query_id: string;
  result_id: Record<string, RatingOptions>;
}

const IndexSearchRatingMap: Record<number, RatingOptions> = {
  1: "bad",
  2: "relevant",
  3: "good",
};

interface ParagraphSearchResult {
  creation_time: string;
  embedding: number[];
  end: number;
  group_id: number;
  is_human_transcript: boolean;
  job_id: number;
  paragraph: string;
  silence: {
    cnt: number;
    total_duration: number;
  };
  speaker: string[];
  start: number;
  wps: number;
  tc_offset: number[][];
  filename: string;
  tags: string[];
  paragraph_id: number;
}

interface SentenceSearchResult {
  creation_time: string;
  embedding: number[];
  end: number;
  group_id: number;
  job_id: number;
  ner_entities: any[];
  paragraph_id: number;
  sentence: string;
  sentiment: string;
  sentiment_score: number;
  speaker: string;
  start: number;
  topic: string;
  topic_score: number;
  words: string[];
  filename: string;
  tags: string[];
  tc_offset: number[][];
}

interface FramesSearchResult {
  blip_llm_vec: any[];
  caption: string;
  clip_vec: any[];
  creation_time: string;
  end: number;
  faces: any[];
  frame: number;
  group_id: number;
  job_id: number;
  objects: any[];
  shot_index: number;
  shot_start_frame: number;
  start: number;
  filename: string;
  tags: string[];
  tc_offset: number[][];
}
interface IndexSearchResult {
  id: string;
  index: string;
  score: number;
  data: SentenceSearchResult | FramesSearchResult | ParagraphSearchResult;
  queryId: string;
}
interface UpdateIndexData {
  idJob: number;
  tags?: string[];
  tcOffsets?: number[][];
  name?: string;
}

type EnrichClientMetadataField = "speaker.keyword" | "tags.keyword";
interface EnrichClientMetadataPayload {
  index: string[];
  field: EnrichClientMetadataField;
}

interface EnrichClientMetadataResponse {
  success: boolean;
  values: {
    buckets: { doc_count: number; key: string }[];
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
  };
}

export type {
  Field,
  SearchType,
  IndexSearchQuery,
  IndexSearchSegment,
  IndexSearchField,
  IndexSearchQueryPayload,
  IndexSearchResult,
  IndexSearchFeedbackPayload,
  SentenceSearchResult,
  FramesSearchResult,
  ParagraphSearchResult,
  RootQuery,
  UpdateIndexData,
  EnrichClientMetadataPayload,
  EnrichClientMetadataResponse,
  EnrichClientMetadataField,
};

export {
  IndexType,
  DEFAULT_RETURN_SIZE,
  IndexSearchRatingMap,
  TextualIndexType,
  metadataField,
};
