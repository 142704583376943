import React, { useMemo } from "react";
import { FixedSizeList as List } from "react-window";

interface VirtualizedListProps {
  items: any[];
  maxHeight: number;
  itemCount: number;
  itemHeight: number;
  render: (item: any, idx: number) => JSX.Element;
  className?: string;
}

export const VirtualizedList = ({
  render,
  items,
  maxHeight,
  itemCount,
  itemHeight,
  className,
}: VirtualizedListProps) => {
  const ListItem = ({ index, style }: any) => (
    <div style={style}>{render(items[index], index)}</div>
  );

  const height = useMemo(() => {
    if (items.length > 4) return maxHeight;
    return items.length * itemHeight;
  }, [items.length, maxHeight, itemHeight]);

  return (
    <List
      height={height}
      itemCount={itemCount}
      itemSize={itemHeight || 35}
      width={"auto"}
      className={className}
    >
      {ListItem}
    </List>
  );
};
