import { MarkWordMetadata } from "@sumit-platforms/types";
import { useTranslation } from "react-i18next";
import { ListItemButton } from "@mui/material";
import _ from "lodash";
import React from "react";

const FindAndReplacePlaceholderText = ({
  highlights,
  selectedIndex,
}: {
  highlights?: MarkWordMetadata[] | null;
  selectedIndex: number | null;
}) => {
  const { t } = useTranslation();

  return (
    <ListItemButton
      disabled
      dir={"ltr"}
      sx={{ paddingLeft: 0, fontSize: "14px" }}
    >
      {highlights?.length
        ? _.isNumber(selectedIndex)
          ? `${t("result")} ${selectedIndex + 1} ${t("of")} ${
              highlights.length
            }`
          : `${highlights.length} ${t("results")}`
        : t("not_selected")}
    </ListItemButton>
  );
};
export default FindAndReplacePlaceholderText;
