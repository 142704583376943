import _ from "lodash";
import React, { Key, useState, useEffect, useCallback } from "react";

export const useKeyPress = (targetKey: Key, refs?: any[]) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const shouldExecute = useCallback(
    (e: KeyboardEvent) => {
      return (
        e.key === targetKey &&
        (_.isEmpty(refs) ||
          (!_.isEmpty(refs) &&
            _.some(
              refs,
              (ref) =>
                ref && ref.current && ref.current.contains(e.target as Node)
            )))
      );
    },
    [refs]
  );

  useEffect(() => {
    const downHandler = (e: KeyboardEvent) => {
      if (shouldExecute(e)) {
        setKeyPressed(true);
        e.preventDefault();
      }
    };

    const upHandler = (e: KeyboardEvent) => {
      if (shouldExecute(e)) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey, refs]);

  return keyPressed;
};
