import {
  JobQueryMetaData,
  JobStatus,
  languages,
  Speaker,
  Tag,
  User,
  UserRole,
  UsersMetadata,
  UserStatus,
} from "@sumit-platforms/types";
import _ from "lodash";
import {
  formatClientJobStatusChip,
  formatDateChip,
  formatDurationChip,
  getMustStatusVisual,
  getOperaStatusVisual,
  getUserRolesString,
  getUserStatusVisual,
  secondsToTimeString,
} from "../../utils";
import { useTranslation } from "react-i18next";
import { TODAY_MIDNIGHT } from "../../constants";
import { Filter } from "../../index";
import { useMemo, useRef } from "react";

export type AvailableFilters =
  | "mustJobStatus"
  | "jobStatus"
  | "communityMemberStatus"
  | "communityMembers"
  | "projectName"
  | "type"
  | "languages"
  | "length"
  | "dueBy"
  | "uploaded"
  | "client"
  | "tags"
  | "payments"
  | "capacity"
  | "userRole"
  | "speakers"
  | "invoices"
  | "split"
  | "users";

export type FiltersBank = Record<AvailableFilters, Filter | null>;

export type FiltersMetaData = {
  maxDuration?: JobQueryMetaData["maxDuration"];
  projectNames?: JobQueryMetaData["projectNames"];
  clients?: JobQueryMetaData["clients"];
  communityMembers?: JobQueryMetaData["communityMembers"];
  userRoles?: UserRole[];
  tags?: Tag[];
  jobTypes?: any[];
  capacityRange?: UsersMetadata["capacityRange"];
  paymentsRange?: UsersMetadata["paymentsRange"];
  levelsRange?: UsersMetadata["levelsRange"];
  jobsDoneRange?: UsersMetadata["jobsDoneRange"];
  speakers?: Speaker[];
  invoices?: UsersMetadata["invoices"];
  users?: JobQueryMetaData["users"];
};

export const useFilters = ({
  filterKeys,
  metaData,
  customJobStatuses,
}: {
  filterKeys: AvailableFilters[];
  metaData: FiltersMetaData;
  customJobStatuses?: JobStatus[];
}) => {
  const { t } = useTranslation();
  const availableJobStatuses = useRef(
    Object.values(customJobStatuses || JobStatus)
  );
  const communityMemberStatuses = useRef(Object.values(UserStatus));
  const tagsOptions = useMemo(() => {
    return metaData.tags
      ? metaData.tags?.map((t) => ({
          label: t.name,
          value: t.idTag,
        }))
      : [];
  }, [metaData.tags]);
  const speakersOptions = useMemo(() => {
    return metaData.speakers
      ? metaData.speakers?.map((t) => ({
          label: t.name,
          value: t.idSpeaker,
        }))
      : [];
  }, [metaData.speakers]);
  const filtersSchemeBank: Partial<FiltersBank> = useMemo(() => {
    const filtersBank: Partial<FiltersBank> = {
      mustJobStatus: {
        key: "status",
        name: t("status"),
        type: "select",
        value: [],
        options: _.unionBy(
          availableJobStatuses.current?.map((s) => {
            return {
              label: t(getMustStatusVisual(s).statusTitle),
              value: s,
            };
          }),
          "label"
        ),
        formatChip: formatClientJobStatusChip,
      },
      jobStatus: {
        key: "status",
        name: t("status"),
        type: "select",
        value: [],
        options: _.unionBy(
          availableJobStatuses.current.map((status) => {
            return {
              label: t(getOperaStatusVisual({ status }).statusTitle),
              value: status,
            };
          }),
          "label"
        ),
        formatChip: (f) => {
          return f.value
            ?.map((status: JobStatus) =>
              t(getOperaStatusVisual({ status }).statusTitle)
            )
            .join(",");
        },
      },
      communityMemberStatus: {
        key: "communityMemberStatus",
        value: [],
        formatChip: (f) => {
          return f.value
            ?.map((s: UserStatus) => t(getUserStatusVisual(s).statusTitle))
            .join(",");
        },
        name: t("status"),
        options: _.unionBy(
          communityMemberStatuses.current?.map((s) => {
            return {
              label: t(getUserStatusVisual(s).statusTitle),
              value: s,
            };
          }),
          "label"
        ),
        type: "select",
      },
      languages: {
        key: ["inputLanguage", "outputLanguage"],
        name: t("language"),
        type: "selectFromTo",
        valueFrom: [],
        valueTo: [],
        options: languages.map((l) => ({
          label: t(l.value),
          value: l.value,
        })),
        formatChip: (f) => {
          return `${f.valueFrom && f.valueFrom[0] ? t(f.valueFrom[0]) : ""} ${
            f.valueTo && f.valueTo[0] ? "- " + t(f.valueTo[0]) : ""
          }`;
        },
      },
      dueBy: {
        key: ["deliveryStart", "deliveryEnd"],
        name: t("due_by"),
        type: "dateFromTo",
        valueFrom: TODAY_MIDNIGHT,
        valueTo: TODAY_MIDNIGHT,
        resetValue: TODAY_MIDNIGHT,
        title: t("set_date_range"),
        formatChip: formatDateChip,
        useDateShortcuts: true,
      },
      uploaded: {
        key: ["uploadedStart", "uploadedEnd"],
        name: t("uploaded"),
        type: "dateFromTo",
        valueFrom: TODAY_MIDNIGHT,
        valueTo: TODAY_MIDNIGHT,
        resetValue: TODAY_MIDNIGHT,
        title: t("set_date_range"),
        formatChip: formatDateChip,
      },
      split: {
        key: "split",
        name: t("split"),
        type: "select",
        value: [],
        options: [
          {
            label: t("parent"),
            value: "parent",
          },
          {
            label: t("child"),
            value: "child",
          },
        ],
        formatChip: (f) => {
          if (!f.value?.length) return "";
          return f.value?.map((s: string) => t(s)).join(",");
        },
      },
    };
    if (metaData.maxDuration) {
      filtersBank.length = {
        key: ["durationStart", "durationEnd"],
        name: t("length"),
        type: "sliderFromTo",
        valueFrom: 0,
        valueTo: metaData?.maxDuration,
        min: 0,
        max: metaData?.maxDuration,
        title: t("set_duration_range"),
        format: secondsToTimeString,
        formatChip: formatDurationChip,
      };
    }
    if (metaData.tags) {
      filtersBank.tags = {
        key: "tagIds",
        name: t("tags"),
        type: "select",
        value: [],
        options: tagsOptions,
        formatChip: (f) => {
          return tagsOptions
            .filter((t) => f.value?.includes(t.value))
            .map((t) => t.label)
            .join(", ");
        },
      };
    }
    if (metaData.jobTypes) {
      filtersBank.type = {
        key: "type",
        name: t("type"),
        type: "select",
        value: [],
        options: metaData.jobTypes.map((type) => ({
          label: t(type.name),
          value: type.name,
        })),
        formatChip: (f) => {
          return f?.value?.join(",");
        },
      };
    }
    if (metaData.clients?.length) {
      filtersBank.client = {
        key: "clientIds",
        name: t("client"),
        type: "select",
        value: [],
        options: metaData.clients.map((c) => ({
          label: c.name,
          value: c.idClient,
        })),
        formatChip: (f) => {
          return metaData
            .clients!.filter((c) => f.value?.includes(c.idClient))
            .map((c) => c.name)
            .join(",");
        },
      };
    }
    if (metaData.projectNames?.length) {
      filtersBank.projectName = {
        key: "projectName",
        name: t("project"),
        type: "select",
        value: [],
        options: metaData.projectNames.map((pn: string) => ({
          label: pn.trim(),
          value: pn.trim(),
        })),
        formatChip: (f) => {
          return f?.value?.join(",");
        },
      };
    }
    if (metaData.communityMembers?.length) {
      filtersBank.communityMembers = {
        key: "userIds",
        name: t("members"),
        type: "select",
        value: [],
        options: metaData.communityMembers.map((cm) => ({
          label: `${cm.firstName} ${cm.lastName}`,
          value: cm.idUser,
        })),
        formatChip: (f) => {
          const selected = f.options
            ?.filter((option: any) => f.value?.includes(option.value))
            ?.map((option: any) => `${option.label}`);
          return selected?.join(", ") || "";
        },
      };
    }
    if (metaData.capacityRange) {
      filtersBank.capacity = {
        key: ["capacityStart", "capacityEnd"],
        type: "sliderFromTo",
        valueFrom: metaData.capacityRange.from,
        valueTo: metaData.capacityRange.to,
        name: t("capacity"),
        format: (value) => value.toString(),
        formatChip: (f) => `from:${f.valueFrom} to:${f.valueTo}`,
        min: 0,
        max: metaData.capacityRange.to,
        title: t("capacity"),
      };
    }
    if (metaData.paymentsRange) {
      filtersBank.payments = {
        key: ["paymentsStart", "paymentsEnd"],
        type: "sliderFromTo",
        valueFrom: metaData.paymentsRange.from,
        valueTo: metaData.paymentsRange.to,
        name: t("payments"),
        format: (value) => value.toString(),
        formatChip: (f) => `from: ${f.valueFrom} to: ${f.valueTo}`,
        min: metaData.paymentsRange.from,
        max: metaData.paymentsRange.to,
        title: t("payments"),
      };
    }

    if (metaData.users?.length) {
      filtersBank.users = {
        key: "uploaders",
        name: t("uploaded_by"),
        type: "select",
        value: [],
        options: metaData.users.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user?.idUser,
        })),
        formatChip: (f) => {
          return metaData
            .users!.filter((u) => f.value?.includes(u.idUser))
            .map((u) => `${u.firstName} ${u.lastName}`)
            .join(", ");
        },
      };
    }

    filtersBank.invoices = {
      key: "invoiceIds",
      name: t("invoice_number"),
      type: "select",
      value: [],
      options: [
        {
          label: t("no_invoice"),
          value: "no_invoice",
        },
        ...(metaData.invoices?.map(({ idInvoice, invoiceNumber }) => ({
          label: invoiceNumber,
          value: +idInvoice,
        })) || []),
      ],

      formatChip: (f) => {
        let chip =
          metaData.invoices
            ?.filter((i) => f.value?.includes(i.idInvoice))
            ?.map((c) => c.invoiceNumber)
            ?.join(",") || "";
        if (f.value?.includes("no_invoice")) {
          chip = t("no_invoice") + ", " + chip;
        }
        return chip;
      },
    };

    if (metaData.userRoles) {
      filtersBank.userRole = {
        key: "userRole",
        value: [],
        formatChip: (f) => {
          return f.value
            ?.map((role: UserRole) => t(getUserRolesString(role)))
            .join(",");
        },
        name: t("role"),
        options: _.unionBy(
          metaData.userRoles.map((role) => {
            return {
              label: t(getUserRolesString(role)),
              value: role,
            };
          }),
          "label"
        ),
        type: "select",
      };
    }
    if (metaData.speakers) {
      filtersBank.speakers = {
        key: "speakerIds",
        name: t("speakers"),
        type: "select",
        value: [],
        options: speakersOptions,
        formatChip: (f) => {
          return speakersOptions
            .filter((s) => f.value?.includes(s.value))
            .map((s) => s.label)
            .join(", ");
        },
      };
    }
    return filtersBank;
  }, [metaData]);

  const getFilters = (filterKeys: AvailableFilters[]) => {
    if (!filterKeys.length || !metaData) return [];
    return Object.values(_.pick(filtersSchemeBank, filterKeys)) as Filter[];
  };

  return {
    filtersScheme: getFilters(filterKeys),
  };
};
