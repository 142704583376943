import React, { useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

interface PlayerProps {
  options: {
    autoplay: boolean;
    sources: { src: string; type: string }[];
    controls?: boolean;
    responsive?: boolean;
    fluid?: boolean;
    poster?: string;
    preload?: string;
  };
  onReady: (player: any) => void;
}

const Player = ({ options, onReady }: PlayerProps) => {
  const videoRef = React.useRef<any>(null);
  const playerRef = React.useRef<any>(null);

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoRef?.current.appendChild(videoElement);
      if (options.poster) {
        videoElement?.style.setProperty("background", options.poster);
      }
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  //Unmount player
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className={"Player"} data-vjs-player>
      <div className={"playerRef"} ref={videoRef} />
    </div>
  );
};

export { Player };
