import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import classNames from "classnames";
import {
  faArrowRightArrowLeft,
  faKeyboard,
  faLock,
  faReplyClock,
  faSave,
  faUnlock,
  faMagnifyingGlass,
} from "@fortawesome/pro-light-svg-icons";
import { faA } from "@fortawesome/pro-solid-svg-icons";
import { Peaks } from "wavesurfer.js/types/backend";
import {
  Badge,
  ConfirmModal,
  KeyboardShortcutsModal,
} from "@sumit-platforms/ui-bazar";
import { useIdleTimer } from "react-idle-timer";

import { getTcOffsetByStartTime } from "@sumit-platforms/ui-bazar/utils";
import {
  disabledButtonGray,
  keyboardShortcuts,
  mainYellow,
} from "@sumit-platforms/ui-bazar/constants";
import { Node } from "slate";
import { ReactEditor } from "slate-react";

import {
  Direction,
  EditorFeatureFlags,
  EditorMode,
  JobRange,
  JobSpeaker,
  JobStatus,
  JobTranslationType,
  JobWithData,
  RangeValidationConfig,
  SaveTriggers,
  Shortcut,
  SubtitlesRange,
  SubtitlesTranslationRange,
  UserSettings,
  ValidationsConfigData,
  Word,
  CustomEditor,
  CustomElement,
} from "@sumit-platforms/types";
import { EditorAction } from "./types/EditorAction";

import EditorService from "./services/EditorService";
import MediaService from "./services/MediaService";
// import TrackingService from "./services/TrackingService";
// import FeatureFlagsService from "./services/FeatureFlagsService";
// import { generateId } from "../../utils/generators";
// import { fileSizeFormat } from "../../utils/formatters";
// import Logger from "./services/Logger";
import JobInfoPanel from "./components/InfoPanel/JobInfoPanel";
import JobActionPanel from "./components/ActionPanel/JobActionPanel";

import Subtitles from "./Subtitles";
// import SubtitlesTranslation from "./SubtitlesTranslation";
import MediaPlayer from "./components/MediaPlayer/MediaPlayer";
import WaveformRanges from "./components/WaveformRanges/WaveformRanges";
// import MessageModal from "../../components/MessageModal/MessageModal";
// import ExportModal from "../../components/common/ExportModal/ExportModal";
// import SelectList from "../../components/common/SelectList/SelectList";
// import LoadingModal from "../../components/LoadingModal/LoadingModal";
// import ExportModalV3 from "../../components/common/ExportModalV3/ExportModalV3";
// import { ExportConfigData } from "../ExportConfig/ExportConfigTypes";
import SubtitlesTranslation from "./SubtitlesTranslation";
import { scrollInto } from "./utils/focusAndScroll";
import { generateId } from "./utils/generateId";
import { createVtt } from "./utils/createVtt";
import { usePlayerStore } from "@sumit-platforms/ui-bazar/store";
import {
  featureFlagsState,
  fpsState,
  speakersState,
  subtitlesValidationState,
  directionState,
  isDisabledState,
} from "./store/states";
import { RecoilRoot, RecoilState, SetRecoilState } from "recoil";
import SlateEditor, { SlateForwardedRef } from "./SlateEditor";

// import config from "../../config";
import "./Editor.scss";

const logger = console;

const readOnlyStatuses = [
  JobStatus.aligning,
  JobStatus.stt,
  JobStatus.pending_source_transcription,
  JobStatus.pending_splits_transcription,
];
const translationsReadOnlyValues = [JobTranslationType.SOURCE_HIDDEN];

export type UpdateTcOffsetFn = ({
  editor,
  rangeIndex,
  startTime,
  tcOffset,
}: {
  editor: CustomEditor;
  rangeIndex: number;
  startTime: number;
  tcOffset: number;
}) => void;

interface EditorProps {
  job: JobWithData;
  jobSettings: {
    subtitlesValidation: ValidationsConfigData;
    peaks?: Peaks;
  };
  setJob: (job: JobWithData) => void;
  save: (job: JobWithData, saveMethod: SaveTriggers) => Promise<void>;
  mode: EditorMode;
  showSubtitles?: boolean;
  jobActions?: EditorAction[];
  toast: (options: any) => void;
  useModal: () => any;
  featureFlags?: EditorFeatureFlags;
  downloadMedia?: (idMedia: number) => Promise<any>;
  userSettings?: UserSettings | null;
  updateUserSettings?: (
    newUserSettings: Partial<UserSettings>
  ) => Promise<void>;
  startAsReadOnly?: boolean;
}

interface EditorImperativeRef {
  saveJob?: () => Promise<void>;
  setIsReadyOnly?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Editor = (
  {
    job,
    jobSettings,
    setJob,
    save,
    mode,
    showSubtitles = false,
    jobActions = [],
    toast,
    useModal,
    featureFlags = {},
    downloadMedia,
    userSettings,
    updateUserSettings,
    startAsReadOnly = false,
  }: EditorProps,
  ref?: React.Ref<EditorImperativeRef>
): JSX.Element => {
  const navigate = useNavigate();
  const { setZoomValue } = usePlayerStore();
  const [queryParameters] = useSearchParams();
  const { t } = useTranslation();
  const [userShortcuts, setUserShortcuts] = useState<Shortcut[]>([]);
  const [isOnHold, setIsOnHold] = useState(false);
  const [sessionId] = useState(generateId("s_"));
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const userActive = useRef(true);
  const [autoSave, setAutoSave] = useState(
    _.isNil(userSettings?.autoSave) ? true : userSettings?.autoSave
  );
  const [autoRewind, setAutoRewind] = useState(0);
  const [initialPlaybackPosition, setInitialPlaybackPosition] = useState(0);
  // const { autoSave, playerRewind, playerForward, autoRewind } =
  //   useSelector((state: AppState) => state.userStore.settings);
  const [ranges, setRanges] = useState<JobRange[]>(job.data.ranges);

  const rangesRef = useRef<JobRange[]>([]);
  const slateRef = useRef<SlateForwardedRef | null>(null);
  const recoilSetter = useRef<SetRecoilState | null>(null);
  const isChangesSaved = useRef(false);
  const editorType = useMemo(
    () =>
      mode === "transcript" &&
      ["protocol", "brief", "interview"].includes(job.type?.typeName) &&
      featureFlags?.contentEditable
        ? "slate"
        : "v3",
    [featureFlags, job.type?.typeName, mode]
  );
  const playerRewind = useMemo(() => 3, []);
  const initialFpsValue = useMemo(
    () => (job.media?.length ? job.media[0]?.fps : 25) || 25,
    [job.media]
  );
  const playerForward = useMemo(() => 3, []);
  const rangesStartTimes = useMemo(
    () => ranges.map((r: JobRange) => r.st),
    [ranges]
  );
  // const focusedRangeIndex = useRef(-1);

  const [focusedRangeIndex, setFocusedRangeIndex] = useState(-1);
  const [focusedRangeWordsString, setFocusedRangeWordsString] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [currentPlayingRangeIndex, setCurrentPlayingRangeIndex] = useState(0);
  // const [jobStreamingState, setJobStreamingState] =
  // useState<JobData["streaming"]>();
  const [jobLang, setJobLang] = useState<string>();
  const [editorDirection, setEditorDirection] = useState<"ltr" | "rtl">("ltr");
  const [timestampsEnterMethod, setTimestampsEnterMethod] = useState<
    "word" | "player"
  >("word");
  const [isMediaPlayerFloating, setIsMediaPlayerFloating] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(startAsReadOnly);
  const [isFindAndReplaceOpen, setIsFindAndReplaceOpen] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        saveJob: async () => {
          await handleSaveJob(SaveTriggers.JOB_LEAVE_CONFIRMATION_MODAL);
        },
        setIsReadOnly,
        isChangesSaved: isChangesSaved.current,
      };
    },
    [handleSaveJob]
  );
  // const [exportConfigPresets, setExportConfigPresets] = useState<

  //   { id: string; config: ExportConfigData }[]
  // >([]);
  useIdleTimer({
    timeout: 31 * 1000,
    onIdle: () => (userActive.current = false),
    onActive: () => (userActive.current = true),
    debounce: 500,
  });

  // const [showExportModal, setShowExportModal] = useState(false);

  const validationConfig = useRef<RangeValidationConfig>();

  const [actions, setActions] = useState<EditorAction[]>([]);
  const [subtitlesData, setSubtitlesData] = useState("");

  const handleSlateStateReset = () => {
    if (!rangesRef.current.length || !slateRef.current?.editor) {
      // means on first mount. we will skip it
      return;
    }
    const existValue = EditorService.formatEditorValueToJobData(
      slateRef.current.editor.children as CustomElement[]
    );
    const currentRanges = job.data.ranges;
    const shouldUpdate = !_.isEqual(existValue, currentRanges);
    // console.log("shouldUpdate :", shouldUpdate);
    // console.log(
    //   `${existValue[0].speakerName} : ${currentRanges[0].speakerName}`
    // );
    if (shouldUpdate) {
      const newSlateValue = EditorService.formatJobDataToEditorValue(job);
      EditorService.expensivelyResetEditorState(
        slateRef.current.editor,
        newSlateValue
      );
      if (recoilSetter.current) {
        setRecoilState(speakersState, job?.data?.speakers || []);
      }
    }
  };

  const initJobData = async () => {
    if (!job) return;
    try {
      const jobLangKey = "outputLanguage";
      const _jobLang = job[jobLangKey][0] || job.inputLanguage[0];
      const languageDirection = EditorService.getLangDirection(_jobLang);

      setJobLang(_jobLang);

      setEditorDirection(languageDirection);

      // setJobStreamingState(job.streaming);
      // getJobRevisions(job);
      updateRanges(job.data?.ranges);

      const newSubtitlesData = createVtt(job.data.ranges);
      setSubtitlesData(newSubtitlesData);

      // await handleExportConfig(jobData);
      const _isReadOnly =
        isReadOnly ||
        readOnlyStatuses.includes(job.status) ||
        translationsReadOnlyValues.includes(job.translation);
      setIsReadOnly(isReadOnly);

      setRecoilState(directionState, languageDirection);
      setRecoilState(isDisabledState, _isReadOnly);

      MediaService.tcOffsets = job.tcOffsets;
      MediaService.setFrameRate(initialFpsValue);
      // initValidation(job.data, _jobLang);
      handleQueryParams();

      handleSlateStateReset();
    } catch (err) {
      logger.error(err);
      // setErrorReason(t("indicator_error_ocurred"));
    }
  };

  useEffect(() => {
    const keyStrokesHandler = async (e: any) => {
      if (!e.ctrlKey && !e.metaKey) return;
      switch (e.code) {
        case "KeyM":
          handleTogglePlay(e);
          break;
        case "KeyS":
          handleSaveJobKeyStroke(e);
          break;
        case "KeyK":
          handleKaraokeKeyStroke(e);
          break;
        case "Period":
          handlePlayRelativeKeyStroke(e, playerForward || 5);
          break;
        case "Comma":
          handlePlayRelativeKeyStroke(e, (playerRewind || 5) * -1);
          break;
      }
    };
    if (featureFlags?.useNewKeyboardShortcuts) return;
    document.addEventListener("keydown", keyStrokesHandler, false);

    return () => {
      document.removeEventListener("keydown", keyStrokesHandler);
    };
  }, [job, ranges, playerRewind, playerForward, autoRewind]);

  const setRecoilState: SetRecoilState = useCallback(
    (state: RecoilState<any>, value: any) => {
      if (!recoilSetter.current) return;
      recoilSetter.current(state, value);
    },
    []
  );

  const getJobSpeakers = useCallback(() => {
    const speakersMap = job.data.ranges.reduce((acc, range) => {
      if (!range.speakerName) return acc;
      if (!acc[range.speakerName as string]) {
        const speakerId = (range as any)?.speakerId || null;
        const speaker = {
          name: range.speakerName,
          id: speakerId,
        };
        acc[range.speakerName] = speaker;
      }
      return acc;
    }, {} as Record<string, JobSpeaker>);

    const speakers = _.values(speakersMap);
    return speakers;
  }, [job]);

  const onBeforeSlateSave = useCallback(() => {
    if (!slateRef.current) return;

    const { editor, updateLastRangeInput } = slateRef.current;
    const values = editor.children as CustomElement[];
    if (!values) return;

    if (updateLastRangeInput) updateLastRangeInput();

    const newRanges = EditorService.formatEditorValueToJobData(values);
    rangesRef.current = newRanges;
  }, []);

  const getWordByTime = useCallback(
    (time: number) => {
      if (!job || !ranges) return null;
      let currentRangeIndex;
      const currentRange = _.find(ranges, (r, i) => {
        if (r.et >= time) {
          currentRangeIndex = i;
          return true;
        }
        return false;
      });
      if (!currentRange || _.isNil(currentRangeIndex)) return null;

      const currentWord = _.find(
        currentRange.words,
        (w, i) => w.end_time >= time
      );

      if (!currentWord) return null;

      let wordStartIndex = -1;
      let wordEndIndex = 0;
      for (const rangeWord of currentRange.words.filter(
        (w) => w.range_ix === currentWord.range_ix
      )) {
        wordStartIndex++;
        if (currentWord === rangeWord) {
          wordEndIndex = wordStartIndex + rangeWord.word.length;
          break;
        }
        wordStartIndex = wordStartIndex + rangeWord.word.length;
      }

      return {
        word: currentWord,
        start_index: wordStartIndex,
        end_index: wordEndIndex,
        range_ix: currentRangeIndex,
      };
    },
    [job, ranges]
  );

  const handleTogglePlay = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      MediaService.togglePlay((autoRewind || 0) * -1);
    },
    [autoRewind]
  );
  const handleSaveJobKeyStroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      if (isReadOnly) return;
      handleSaveJob(SaveTriggers.KEYBOARD_SHORTCUT);
    },
    [handleSaveJob, isReadOnly]
  );

  const scrollToWord = (rangeIndex: number) => {
    const editor = slateRef?.current?.editor;
    if (editor) {
      const node = Node.get(editor, [rangeIndex]);
      const domNode = ReactEditor.toDOMNode(editor, node);
      if (domNode) {
        domNode.scrollIntoView({ behavior: "instant", block: "start" });
      }
    }
  };

  const selectCurrentWord = useCallback(
    (options?: { delay: number }) => {
      // TrackingService.reportEvent("select_playing_word", {
      //   job_type: job?.jobType,
      //   room_id: job?.roomId,
      // });

      const currentWord: {
        word: Word;
        start_index: number;
        end_index: number;
        range_ix: number;
      } | null = getWordByTime(MediaService.currentTime);
      if (!currentWord) return;
      setTimeout(() => {
        scrollToWord(currentWord.range_ix);
      }, options?.delay || 200);
    },
    [getWordByTime]
  );

  const handleKaraokeKeyStroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      if (isReadOnly) return;
      selectCurrentWord();
    },
    [isReadOnly, selectCurrentWord]
  );

  const handlePlayRelativeKeyStroke = useCallback(
    (e: React.KeyboardEvent, offset: number) => {
      e.preventDefault();
      MediaService.playRelative(offset);
    },
    []
  );

  const onUserShortcutEdit = useCallback(
    async (newShortcut: Shortcut[]) => {
      if (!updateUserSettings) return;
      const clonedUserShortcuts = _.clone(
        userSettings?.keyboardShortcuts || []
      );
      const updatedUserShortcuts = _.chain([
        ...newShortcut,
        ...clonedUserShortcuts,
      ])
        .uniqBy("action")
        .map(
          (shortcut) => _.pick(shortcut, ["action", "keys"]) // save only the action and keys
        )
        .value();

      await updateUserSettings({
        ...userSettings,
        keyboardShortcuts: updatedUserShortcuts as Partial<Shortcut[]>,
      });
    },
    [updateUserSettings, userSettings]
  );

  const toggleIsFindAndReplaceOpen = useCallback(() => {
    if (!featureFlags?.findAndReplace) return;
    const isOpen = !isFindAndReplaceOpen;
    setIsFindAndReplaceOpen(isOpen);
  }, [setIsFindAndReplaceOpen, isFindAndReplaceOpen]);

  const handleOpenKeyboardModal = useCallback(() => {
    setModalType("info");
    setModalContent(
      <KeyboardShortcutsModal
        userShortcuts={userShortcuts || []}
        onShortcutEdit={onUserShortcutEdit}
        closeModal={clearModalContent}
        mode={mode}
        editorType={editorType}
      />
    );
  }, [
    editorType,
    setModalType,
    setModalContent,
    userShortcuts,
    onUserShortcutEdit,
    clearModalContent,
    mode,
  ]);

  const { userShortcuts: userShortcutsWithDefaults } = useKeyboardShortcuts({
    handlers: {
      TOGGLE_PLAY: handleTogglePlay,
      SAVE_JOB: handleSaveJobKeyStroke,
      MARK_CURRENT_WORD: handleKaraokeKeyStroke,
      OPEN_KEYBOARD_SHORTCUTS_MODAL: handleOpenKeyboardModal,
      PLAY_FORWARD: (e: React.KeyboardEvent) =>
        handlePlayRelativeKeyStroke(e, playerForward || 5),
      PLAY_BACKWARD: (e: React.KeyboardEvent) =>
        handlePlayRelativeKeyStroke(e, (playerRewind || 5) * -1),
    },
    ref: document.body,
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  const onUserSettingsChange = useCallback(
    async (updatedUserSettings: Partial<UserSettings>) => {
      if (updateUserSettings) {
        updateUserSettings(updatedUserSettings);
      }
    },
    [updateUserSettings]
  );

  const handleAutoSaveChange = async () => {
    const newValue = !autoSave;
    setAutoSave(newValue);
  };

  useEffect(() => {
    const updatedUserSettings = { autoSave };
    const currentUserSettings = _.omit(userSettings, "shortcuts");
    if (
      !_.isEqual(currentUserSettings.autoSave, updatedUserSettings.autoSave)
    ) {
      onUserSettingsChange(updatedUserSettings);
    }
  }, [autoSave]);

  useEffect(() => {
    // TODO: replace to useMemo to prevent renders
    setActions([
      {
        key: "findAndReplace",
        label: t("find_and_replace"),
        icon: faMagnifyingGlass,
        onClick: toggleIsFindAndReplaceOpen,
        selected: isFindAndReplaceOpen,
        hide: !featureFlags?.findAndReplace,
      },
      {
        key: "readOnly",
        label: isReadOnly ? t("unlock") : t("lock"),
        icon: isReadOnly ? faUnlock : faLock,
        selected: isReadOnly,
        onClick: () => {
          let newIsReadOnly = isReadOnly;
          setIsReadOnly((prevState) => {
            newIsReadOnly = !prevState;
            return newIsReadOnly;
          });
          setRecoilState(isDisabledState, newIsReadOnly);
        },
      },
      {
        key: "autoRewind",
        label: t("auto_rewind"),
        icon: faReplyClock,
        onClick: () =>
          setAutoRewind((prevautoRewind) => (prevautoRewind > 0 ? 0 : 3)),
        selected: autoRewind > 0,
      },
      {
        key: "direction",
        label: t(editorDirection),
        icon: faArrowRightArrowLeft,
        onClick: () => {
          let newDir: Direction = editorDirection;
          setEditorDirection((dir) => {
            newDir = dir === "ltr" ? "rtl" : "ltr";
            return newDir as Direction;
          });
          setRecoilState(directionState, newDir);
        },
      },
      {
        key: "keyboardShortcuts",
        label: t("shortcuts"),
        icon: faKeyboard,
        onClick: handleOpenKeyboardModal,
        hide: !featureFlags?.useNewKeyboardShortcuts,
      },
      {
        key: "save",
        label: t("save"),
        icon: faSave,
        onClick: () => handleSaveJob(SaveTriggers.SAVE_BUTTON),
        selected: autoSave,
        disabled: isReadOnly,
        children: [
          {
            key: "autoSave",
            label: t("auto_save_toggle"),
            icon: faA,
            color: autoSave ? mainYellow : disabledButtonGray,
            onClick: handleAutoSaveChange,
            disabled: isReadOnly,
          },
        ],
      },
    ]);
  }, [
    job,
    ranges,
    autoSave,
    autoRewind,
    handleOpenKeyboardModal,
    isReadOnly,
    isFindAndReplaceOpen,
  ]);

  useEffect(() => {
    initJobData();
  }, [job.data]);

  useEffect(() => {
    if (showSubtitles) {
      //Setting zoom value to 100 if job has subtitiles e.g ranges
      setZoomValue(100);
    } else {
      //In other cases without ranges like protocols and interviews zoom is set to 0 e.g no zoom at all.
      setZoomValue(0);
    }
  }, [showSubtitles]);

  useEffect(() => {
    setUserShortcuts(userShortcutsWithDefaults);
  }, [userSettings, userShortcutsWithDefaults]);

  const handleQueryParams = async () => {
    const time = Number(queryParameters.get("t"));
    const tc = Number(queryParameters.get("tc"));
    let _time = time;
    if (tc) {
      _time = Number(tc) - getTcOffsetByStartTime(job.tcOffsets);
    }
    if (_time) {
      MediaService.setOffset(_time);
      setInitialPlaybackPosition(_time);
      selectCurrentWord({ delay: 500 });
    }
  };

  // Actions
  const updateRanges = async (newRanges: JobRange[], rangeIndex?: number) => {
    const updatedRanges = [...newRanges];
    await validateJobRanges(updatedRanges, rangeIndex);

    rangesRef.current = updatedRanges;
    setRanges(updatedRanges);

    if (job && showSubtitles) {
      const newSubtitlesData = createVtt(updatedRanges);
      setSubtitlesData(newSubtitlesData);
    }

    if (
      !isChangesSaved.current &&
      !_.isEmpty(job.data?.ranges) &&
      !_.isEmpty(rangesRef.current)
    ) {
      const isChanged = !_.isEqual(rangesRef.current, job.data?.ranges);
      isChangesSaved.current = isChanged;
    }
  };

  // const initValidation = async (_job: JobWithData, lang: string) => {
  //   const _validationConfig = await EditorService.getValidationConfig(_job);
  //   validationConfig = _validationConfig;
  //   updateRanges(_job.data?.ranges);
  // };

  // const saveUserLastPosition = () => {
  //   const editorContainerEl = document.getElementById("editorContainer");

  //   const scrollOffsetTop = _.get(editorContainerEl, "scrollTop")
  //     ? (_.get(editorContainerEl, "scrollTop") as number)
  //     : 0;
  //   const rangeEl = document.getElementById(
  //     `range-${focusedRangeIndex.current}`
  //   ) as HTMLTextAreaElement;
  //   if (rangeEl) {
  //     const selectionStart = rangeEl.selectionStart;
  //     if (
  //       _.isNumber(selectionStart) &&
  //       focusedRangeIndex.current >= 0 &&
  //       job?.roomId
  //     ) {
  //       EditorService.saveUserLastPosition({
  //         jobId: job.roomId,
  //         cursorPosition: selectionStart,
  //         rangeIx: focusedRangeIndex.current,
  //         playbackPosition: MediaService.currentTime,
  //         scrollOffsetTop,
  //       });
  //     }
  //   }
  // };

  const openConfirmModal = (
    title: string,
    message: string,
    confirm: () => void,
    closeAfterConfirm = true
  ): void => {
    setModalType("danger");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={clearModalContent}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  };

  async function handleSaveJob(saveMethod: SaveTriggers) {
    // if (FeatureFlagsService.isEnabled("saveUserLastPosition", loggedInUser)) {
    //   saveUserLastPosition();
    // }
    if (slateRef.current?.editor && editorType === "slate") {
      onBeforeSlateSave();
    }

    const updatedJob = getJobData();

    try {
      // console.log({ type: "info", tt: t("saving") }));
      await save(updatedJob, saveMethod);
      isChangesSaved.current = false;
      // console.log({ type: "success", tt: t("save_success") }));
      // idleTimer.reset();
    } catch (err) {
      // console.log({ type: "failure", tt: t("save_fail") }));
    }
  }

  const handleCurrentTimeUpdate = (currentTime: number) => {
    const currentRangeIndex = _.findIndex(
      rangesStartTimes,
      (st: number, i: number) => {
        return currentTime > st && rangesStartTimes[i + 1] > currentTime;
      }
    );
    setCurrentPlayingRangeIndex(currentRangeIndex);
  };

  const getJobData = () => {
    const updatedJob = {
      ...job,
      data: {
        ...job.data,
        ranges: rangesRef.current,
      },
    };
    return updatedJob;
  };

  const validateJobRanges = async (ranges: JobRange[], rangeIndex?: number) => {
    if (
      !jobSettings.subtitlesValidation ||
      !job.type.typeName.includes("subtitle")
    )
      return;
    let rangesToValidate = ranges;

    if (!_.isNil(rangeIndex)) {
      // Validating only current edited range with prev + next ranges
      const firstRangeIndex = Math.max(rangeIndex - 1, 0);
      const lastRangesIndex = rangeIndex + 1;

      rangesToValidate = ranges.slice(firstRangeIndex, lastRangesIndex + 1);
    }

    await EditorService.validateJobRanges(
      rangesToValidate,
      jobSettings.subtitlesValidation
    );
  };

  // const approveJob = async () => {
  //   if (!job) return;
  //   try {
  //     await saveJob("done");
  //     navigate("/my-jobs");
  //   } catch (err) {
  //     logger.error("failed to done/approve meeting");
  //   }
  // };

  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (autoSave && userActive.current) {
        handleSaveJob(SaveTriggers.AUTO_SAVE);
      }
    }, 1000 * 60 * 1);
    if (!autoSave) {
      clearInterval(autoSaveInterval);
    }
    return () => clearInterval(autoSaveInterval);
  }, [autoSave]);

  // const handleUserIdle = () => {
  //   TrackingService.reportEvent("idle_start");
  // };

  // const handleUserActive = () => {
  //   const lastIdleTime = idleTimer.getLastActiveTime()?.getTime();
  //   if (lastIdleTime) {
  //     const idleDuration = Date.now() - lastIdleTime;
  //     TrackingService.reportEvent("idle_stop", {
  //       idle_duration: idleDuration,
  //       event_type: "idle_stop",
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (userActive) {
  //     handleUserActive();
  //   } else {
  //     handleUserIdle();
  //   }
  // }, [userActive]);

  const addActions = async (newActions: EditorAction[]) => {
    setActions((prevActions) =>
      _.uniqBy([...newActions, ...prevActions], "key")
    );
  };

  // useEffect(() => {
  //   const _actions = actions.map((a) => {
  //     const timestampsEnterMethodActionLabel = t("timestamps_enter_method_1", {
  //       timestampsEnterMethod,
  //     });
  //     return a.key !== "timestampsEnterMethod"
  //       ? a
  //       : { ...a, label: timestampsEnterMethodActionLabel };
  //   });
  //   setActions(_actions);
  // }, [timestampsEnterMethod]);

  const toggleTimestampsEnterMethod = () => {
    setTimestampsEnterMethod((preveEnterMethod) => {
      const _enterMethod = preveEnterMethod === "word" ? "player" : "word";
      return _enterMethod;
    });
  };
  // -Actions

  // -Job

  // Words
  // - Words

  // Ranges
  // const setFocusedRangeIndex = (rangeIx: number) => {
  //   console.log({ rangeIx });
  //   focusedRangeIndex.current = rangeIx;
  //   console.log(focusedRangeIndex.current);
  // };

  const updateRangeTimes = ({
    rangeIndex,
    start,
    end,
    method,
  }: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text" | "waveform";
  }) => {
    const updatedRanges = [...rangesRef.current];

    if (start) {
      const updatedStart = start;
      updatedRanges[rangeIndex].st = updatedStart;
      updatedRanges[rangeIndex].words[0].start_time = updatedStart;
      updatedRanges[rangeIndex].words[0].time_edit = true;
    }

    if (end) {
      const updatedEnd = end;
      const lastWordIndex = updatedRanges[rangeIndex].words.length - 1;
      updatedRanges[rangeIndex].et = updatedEnd;
      updatedRanges[rangeIndex].words[lastWordIndex].end_time = updatedEnd;
      updatedRanges[rangeIndex].words[lastWordIndex].time_edit = true;
    }

    if (job && showSubtitles) {
      const newSubtitlesData = "updateRangeTimes";
      setSubtitlesData(newSubtitlesData);
    }

    updatedRanges[rangeIndex].time_edit = true;

    updateRanges(updatedRanges, rangeIndex);
  };

  const updateTcOffset: UpdateTcOffsetFn = ({
    editor,
    rangeIndex,
    startTime,
    tcOffset,
  }) => {
    let updatedTcOffsetArray = job.tcOffsets ? [...job.tcOffsets] : [[0, 0]];
    if (rangeIndex === 0) {
      updatedTcOffsetArray = [[0, tcOffset - startTime]];
    } else {
      const filteredTcOffsetArray = updatedTcOffsetArray.filter(
        (tc) => tc[0] < startTime
      );

      updatedTcOffsetArray = _.sortBy(
        [...filteredTcOffsetArray, [startTime, tcOffset]],
        ([tcStartTime]) => tcStartTime
      );
    }

    MediaService.tcOffsets = updatedTcOffsetArray;
    const updatedJob = {
      ...job,
      data: { ...job.data, ranges: EditorService.getRanges(editor) },
      tcOffsets: updatedTcOffsetArray,
    };

    const newSlateState = EditorService.formatJobDataToEditorValue(updatedJob);

    EditorService.expensivelyResetEditorState(editor, newSlateState);
  };

  // - Ranges
  return (
    <RecoilRoot
      initializeState={({ set }) => {
        recoilSetter.current = set;
        setRecoilState(featureFlagsState, featureFlags);
        setRecoilState(fpsState, initialFpsValue);
        setRecoilState(directionState, editorDirection);
        setRecoilState(isDisabledState, isReadOnly);
        setRecoilState(speakersState, getJobSpeakers());
        setRecoilState(
          subtitlesValidationState,
          jobSettings.subtitlesValidation
        );
      }}
    >
      <div className={classNames("EditableEditor", mode, editorDirection)}>
        <div className="headerContainer">
          <div className="paddingContainer">
            <JobInfoPanel job={job} />
            <JobActionPanel
              getJobData={getJobData}
              editorDirection={editorDirection}
              actions={[...jobActions, ...actions]}
            />
          </div>
        </div>
        {mode === "transcript" && (
          <SlateEditor
            job={job}
            updateTcOffset={updateTcOffset}
            className={"rangesContainer"}
            ref={slateRef}
            isFindAndReplaceOpen={isFindAndReplaceOpen}
            toggleIsFindAndReplaceOpen={toggleIsFindAndReplaceOpen}
          />
        )}
        {mode === "subtitles" && (
          <Subtitles
            job={job}
            saveJob={handleSaveJob}
            ranges={rangesRef.current as SubtitlesRange[]}
            updateRanges={updateRanges}
            addActions={addActions}
            currentPlayingRangeIndex={currentPlayingRangeIndex}
            timestampsEnterMethod={timestampsEnterMethod}
            toggleTimestampsEnterMethod={toggleTimestampsEnterMethod}
            updateRangeTimes={updateRangeTimes}
            editorDirection={editorDirection}
            isDisabled={isReadOnly}
            toast={(msg: string, type: string) =>
              console.log({ type, txt: msg })
            }
          />
        )}
        {mode === "subtitles-translation" && (
          <SubtitlesTranslation
            job={job}
            ranges={rangesRef.current as SubtitlesTranslationRange[]}
            updateRanges={updateRanges}
            currentTime={currentTime}
            editorDirection={editorDirection}
            addActions={addActions}
            isDisabled={isReadOnly}
            currentPlayingRangeIndex={currentPlayingRangeIndex}
            featureFlags={featureFlags}
          />
        )}

        {!_.isEmpty(job.media) && (
          <MediaPlayer
            // loggedInUser={loggedInUser}
            initialPlaybackPosition={initialPlaybackPosition}
            currentCaption={""} //getCurrentCaptionWords()
            onCurrentTimeUpdate={handleCurrentTimeUpdate}
            direction={editorDirection}
            mediaSources={job.media}
            offsets={{ 0: 0 }}
            subtitlesData={subtitlesData}
            displayText={showSubtitles}
            jobId={job.idJob}
            tcOffsets={job.tcOffsets}
            downloadMedia={downloadMedia}
          >
            <WaveformRanges
              ranges={showSubtitles ? rangesRef.current : []}
              peaks={jobSettings.peaks}
              updateRangeTimes={updateRangeTimes}
              jobId={job.idJob}
              initialZoomValue={showSubtitles ? 50 : 0}
              showZoomOption={!!showSubtitles}
              focusRange={(rangeIndex) =>
                scrollInto(`rangeContainer-${rangeIndex}`, "editorContainer")
              }
              initialPlaybackPosition={initialPlaybackPosition}
              disabled={isReadOnly}
            />
          </MediaPlayer>
        )}
      </div>
    </RecoilRoot>
  );
};

export default forwardRef(Editor);
