import _ from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { Box } from "@mui/material";
import { ExtractResult } from "@sumit-platforms/types";
import {
  extractDefaultThumbnail,
  loadingVideoPoster,
} from "@sumit-platforms/common-assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faForward,
  faPlay,
  faStop,
} from "@fortawesome/pro-light-svg-icons";
import videojs from "video.js";
import { Player } from "../../core/Player/Player";

import "./ExtarctMediaPlayer.scss";

interface ExtractMediaPlayerProps {
  extractResult: ExtractResult;
  currentPlayingIndex: string | null;
  setCurrentPlayingIndex: React.Dispatch<React.SetStateAction<string | null>>;
}

const AUTO_PLAY = true;

export const ExtractMediaPlayer = ({
  extractResult,
  currentPlayingIndex,
  setCurrentPlayingIndex,
}: ExtractMediaPlayerProps) => {
  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [player, setPlayer] = useState<any>(null);
  const isPlaying = useRef<boolean>(false);

  const [playerOptions] = useState({
    preload: "none",
    autoplay: AUTO_PLAY,
    controls: false,
    responsive: true,
    fluide: true,
  });

  const handlePlayerReady = useCallback((_player: any) => {
    _player.on("ready", () => {
      // videojs.log("ready");
      setPlayer(_player);
      setIsMediaLoading(true);
    });

    _player.on("canplay", () => {
      // videojs.log("canplay");
      setIsMediaLoading(false);
    });

    _player.on("pause", () => {
      // videojs.log("pause");
      isPlaying.current = false;
    });

    _player.on("play", () => {
      // videojs.log("play");
      isPlaying.current = true;
    });

    _player.on("dispose", () => {
      videojs.log("dispose");
      setPlayer(null);
    });
  }, []);

  const play = useCallback(() => {
    //Replacing current index to play
    if (currentPlayingIndex !== extractResult.resultId) {
      setCurrentPlayingIndex(extractResult.resultId);
      //Otherwise play
    } else {
      if (!player) return;
      player.play();
    }
  }, []);

  const onPlayClick = () => {
    play();
  };

  const onStopClick = () => {
    setCurrentPlayingIndex("");
  };

  const onMouseEnterVideoFrame = () => {
    play();
  };

  const onMouseLeaveVideoFrame = () => {
    setCurrentPlayingIndex("");
  };

  const jump = (gap: number) => {
    if (!player) return;
    if (player.paused()) {
      player.play();
    }
    player.currentTime(player.currentTime() + gap);
  };

  const speedChangeOnMouseDown = (speed: number) => {
    if (!player) return;
    if (player.paused()) {
      player.play();
    }
    player.playbackRate(speed);
  };

  const speedChangeOnMouseUp = () => {
    if (!player) return;
    player.playbackRate(1);
  };

  return (
    <Box
      id={extractResult.resultId}
      display={"flex"}
      justifyContent={"center"}
      className="ExtractMediaPlayer"
    >
      <Box
        className={"mediaWrapper"}
        // onMouseEnter={onMouseEnterVideoFrame}
        // onMouseLeave={onMouseLeaveVideoFrame}
      >
        {currentPlayingIndex === extractResult.resultId &&
        extractResult.media ? (
          <Box className="extractVideoPlayer">
            <Player
              options={{
                ...playerOptions,
                sources: [
                  {
                    src: `${extractResult.media}#t=${extractResult.start}`,
                    type: "",
                  },
                ],
                poster: extractResult.thumbnail || loadingVideoPoster,
              }}
              onReady={handlePlayerReady}
            />
          </Box>
        ) : (
          <img src={extractResult.thumbnail || extractDefaultThumbnail} />
        )}
        {!isMediaLoading ? (
          <Box className={"actionsPanel"}>
            <Box className={"actions"}>
              {currentPlayingIndex === extractResult.resultId && player ? (
                <>
                  <FontAwesomeIcon
                    onClick={() => jump(-3)}
                    icon={faBackward}
                  ></FontAwesomeIcon>
                  <FontAwesomeIcon
                    onClick={onStopClick}
                    icon={faStop}
                  ></FontAwesomeIcon>
                  <FontAwesomeIcon
                    icon={faForward}
                    onClick={() => jump(3)}
                  ></FontAwesomeIcon>
                </>
              ) : (
                <Box className={"mainPlayButtonWrapper"} onClick={onPlayClick}>
                  <FontAwesomeIcon icon={faPlay}></FontAwesomeIcon>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <img src={extractResult.thumbnail || extractDefaultThumbnail} />
        )}
      </Box>
    </Box>
  );
};
