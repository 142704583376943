import { Shortcut, ShortcutAction, ShortcutType } from "@sumit-platforms/types";

export const keyboardShortcuts: Shortcut[] = [
  {
    action: ShortcutAction.CREATE_NEW_ANNOTATION,
    editorTypes: ["v3", "slate"],
    keys: ["Control+Enter"],
    type: ShortcutType.EDITING,
    editorModes: ["transcript"],
  },
  {
    action: ShortcutAction.ANNOTATION_FROM_ANNOTATION,
    editorTypes: ["v3"],
    keys: ["$mod+Shift+Enter"],
    type: ShortcutType.EDITING,
    editorModes: ["transcript"],
  },
  {
    action: ShortcutAction.BREAK_RANGE,
    editorTypes: ["v3"],
    keys: ["Enter"],
    type: ShortcutType.EDITING,
    editorModes: ["transcript"],
  },
  {
    action: ShortcutAction.GLITCH_TEXT_TO_NEXT_RANGE,
    editorTypes: ["v3", "slate"],
    keys: ["Enter"],
    type: ShortcutType.EDITING,
    editorModes: ["subtitles", "subtitles-translation"],
  },
  {
    action: ShortcutAction.BREAK_TO_NEW_RANGE,
    editorTypes: ["v3", "slate"],
    keys: ["Control+Enter"],
    type: ShortcutType.EDITING,
    editorModes: ["subtitles", "subtitles-translation"],
  },
  {
    //TODO: Check what does it do ? only scrollInto ?
    action: ShortcutAction.BREAK_RANGE_LINE,
    editorTypes: ["v3", "slate"],
    keys: ["Shift+Enter"],
    type: ShortcutType.EDITING,
    editorModes: ["subtitles", "subtitles-translation"],
    hide: true,
  },
  {
    action: ShortcutAction.CURSOR_RIGHT_LEFT,
    editorTypes: ["v3", "slate"],
    keys: ["ArrowLeft", "ArrowRight"],
    type: ShortcutType.NAVIGATION,
    editable: false,
    hide: true,
  },
  {
    action: ShortcutAction.DELETE_ENTIRE_RANGE,
    editorTypes: ["v3", "slate"],
    keys: ["$mod+Shift+Backspace"],
    type: ShortcutType.EDITING,
    editorModes: ["subtitles", "subtitles-translation"],
  },
  {
    action: ShortcutAction.UNDO,
    editorTypes: ["slate"],
    keys: ["$mod+KeyZ"],
    type: ShortcutType.EDITING,
    editorModes: ["transcript"],
  },
  {
    action: ShortcutAction.REDO,
    editorTypes: ["slate"],
    keys: ["$mod+Shift+KeyZ"],
    type: ShortcutType.EDITING,
    editorModes: ["transcript"],
  },
  {
    action: ShortcutAction.REVEAL_TRANSLATION,
    editorTypes: ["v3", "slate"],
    keys: ["Control+KeyL"],
    type: ShortcutType.EDITING,
    editorModes: ["subtitles-translation"],
  },
  {
    action: ShortcutAction.JUMP_TO_WORD,
    editorTypes: ["v3", "slate"],
    keys: ["Alt+Enter"],
    type: ShortcutType.NAVIGATION,
  },
  {
    action: ShortcutAction.JUMP_TO_NEXT_RANGE,
    editorTypes: ["v3", "slate"],
    keys: ["Tab"],
    type: ShortcutType.NAVIGATION,
    editable: true, //TODO: double check that
  },
  {
    action: ShortcutAction.JUMP_TO_PREV_RANGE,
    editorTypes: ["v3", "slate"],
    keys: ["Shift+tab"],
    type: ShortcutType.NAVIGATION,
    editable: true, //TODO: double check that
  },
  {
    action: ShortcutAction.MERGE_RANGE,
    editorTypes: ["v3", "slate"],
    keys: ["Backspace"],
    type: ShortcutType.EDITING,
    editable: false,
    hide: true,
  },
  {
    action: ShortcutAction.MERGE_WITH_NEXT_RANGE,
    editorTypes: ["v3", "slate"],
    keys: ["Delete"],
    type: ShortcutType.EDITING,
    editable: false, //TODO: fix that to be editable
    hide: true,
  },
  {
    action: ShortcutAction.PLAY_BACKWARD,
    editorTypes: ["v3", "slate"],
    keys: ["$mod+Comma"],
    type: ShortcutType.PLAYER,
  },
  {
    action: ShortcutAction.PLAY_FORWARD,
    editorTypes: ["v3", "slate"],
    keys: ["$mod+Period"],
    type: ShortcutType.PLAYER,
  },
  {
    action: ShortcutAction.PREVENT_CUT,
    editorTypes: ["v3", "slate"],
    keys: ["Control+KeyX", "Meta+KeyX"],
    type: ShortcutType.EDITING,
    editable: false,
    hide: true,
  },
  {
    action: ShortcutAction.SAVE_JOB,
    editorTypes: ["v3", "slate"],
    keys: ["$mod+KeyS"],
    type: ShortcutType.EDITING,
  },
  {
    action: ShortcutAction.MARK_CURRENT_WORD,
    editorTypes: ["v3"],
    keys: ["Meta+KeyK"],
    type: ShortcutType.TIMING,
  },
  {
    action: ShortcutAction.KARAOKE_MODE,
    editorTypes: ["slate"],
    keys: ["Control+Alt+Shift+KeyK"],
    type: ShortcutType.TIMING,
    editorModes: ["transcript"],
    hide: true,
  },
  {
    action: ShortcutAction.FIND_AND_REPLACE,
    editorTypes: ["slate"],
    keys: ["$mod+KeyF"],
    type: ShortcutType.EDITING,
    editorModes: ["transcript"],
  },
  {
    action: ShortcutAction.OPEN_SPEAKERS,
    editorTypes: ["v3", "slate"],
    keys: ["Control+KeyD"],
    type: ShortcutType.EDITING,
    editorModes: ["transcript"],
  },
  {
    action: ShortcutAction.TOGGLE_PLAY,
    editorTypes: ["v3", "slate"],
    keys: ["Control+KeyM", "Meta+KeyM"],
    type: ShortcutType.PLAYER,
  },
  {
    action: ShortcutAction.SHOW_PLAYER_CONTROLS,
    editorTypes: ["v3", "slate"],
    keys: ["Shift+Control+ArrowUp"],
    type: ShortcutType.PLAYER,
  },
  {
    action: ShortcutAction.HIDE_PLAYER_CONTROLS,
    editorTypes: ["v3", "slate"],
    keys: ["Shift+Control+ArrowDown"],
    type: ShortcutType.PLAYER,
  },
  {
    action: ShortcutAction.OPEN_KEYBOARD_SHORTCUTS_MODAL,
    editorTypes: ["v3", "slate"],
    keys: ["Control+/"],
    type: ShortcutType.GLOBAL,
  },
  {
    action: ShortcutAction.CLOSE_MODAL,
    editorTypes: ["v3", "slate"],
    keys: ["Escape"],
    type: ShortcutType.GLOBAL,
    editable: false,
    hide: true,
  },
];

export const macosShortcuts = [
  "command+tab", // Switch between open applications
  "command+option+esc", // Force quit applications
  "command+shift+keyq", // Log out
  "command+option+keym", // Minimize all windows
  "command+space", // Open Spotlight search
  "command+keyt", // Open new tab (browser)
  "command+keyw", // Close current tab (browser)
  "command+shift+keyt", // Reopen last closed tab (browser)
  "command+keyl", // Focus address bar (browser)
  "command+keyn", // Open a new window (Finder)
  "command+shift+keyn", // Create a new folder (Finder)
  "command+keyi", // Show information of selected item (Finder)
  "command+keyr", // Refresh or reload (Finder/browser)
  "command+keyp", // Print
  "command+keyh", // Hide application
  "command+option+keyh", // Hide other applications
  "command+keyd", // Duplicate (Finder)
  "command+arrowleft", // cursor left
  "command+arrowright", // cursor right
  "alt+arrowleft", // cursor left
  "alt+arrowright", // cursor right
];

export const windowsShortcuts = [
  "alt+tab", // Switch between open applications
  "ctrl+alt+delete", // Open Task Manager or security options
  "alt+f4", // Close the current window
  "windows+keyd", // Show desktop
  "windows+keyl", // Lock the screen
  "ctrl+keyt", // Open new tab (browser)
  "ctrl+keyw", // Close current tab (browser)
  "ctrl+shift+keyt", // Reopen last closed tab (browser)
  "ctrl+tab", // Switch to next tab (browser)
  "ctrl+shift+tab", // Switch to previous tab (browser)
  "ctrl+keyl", // Focus address bar (browser)
  "alt+keyd", // Focus address bar (browser)
  "ctrl+keyn", // Open a new window (File Explorer)
  "ctrl+shift+keyn", // Create a new folder (File Explorer)
  "f2", // Rename selected item (File Explorer)
  "f5", // Refresh the active window (File Explorer)
  "ctrl+keyp", // Print
  "ctrl+keyh", // Find and replace
  "ctrl+keyr", // Refresh (browser)
  "ctrl+keyd", // Bookmark (browser)
  "ctrl+arrowleft", // cursor left
  "ctrl+arrowright", // cursor right
  "alt+arrowleft", // cursor left
  "alt+arrowright", // cursor right
];
